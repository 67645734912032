<template>
  <div class="container">
    <HeadTitle title="缴费记录" return-path="HealthMeal"></HeadTitle>
    <div class="select-type">
      <span class="button" @click="isShowMonthDialog = true">
        <span class="month">{{ date }}</span>
        <img src="../../../assets/icon-select-type.png" alt="" />
      </span>
    </div>
    <div class="record-list" v-if="list.length > 0">
      <div class="record">
        <div class="head">
          <div class="head-title">学生姓名</div>
          <div class="head-title">手机号</div>
          <!-- <div class="head-title">金额</div> -->
          <div class="head-title">缴费状态</div>
        </div>
        <div class="body">
          <div class="list" v-for="item in list" :key="item.id">
            <div class="list-item">{{ item.userName }}</div>
            <div class="list-item">{{ item.phone }}</div>
            <div
              class="list-item"
              :class="item.statusS === 6 ? 'abnormal' : 'normal'"
            >
              {{ item.statusS === 6 ? '欠费' : '正常' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <DefaultImg v-else></DefaultImg>

    <!-- 月份选择 -->
    <van-popup v-model="isShowMonthDialog" position="bottom">
      <van-picker
        v-model="date"
        show-toolbar
        :columns="monthList"
        @confirm="onConfirmMonth"
        @cancel="isShowMonthDialog = false"
        value-key="label"
      />
    </van-popup>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import DefaultImg from '@/components/defaultImg/index.vue';

import HeadTitle from '@/components/headTitle/index.vue';
import dayjs from 'dayjs';
import { getPayMentRecords } from '@/api/health-meal.js';

export default {
  components: { HeadTitle, DefaultImg },
  data() {
    return {
      isShowMonthDialog: false,
      show: false,
      classId: this.$route.query.classId,
      // 当
      date: dayjs().format('YYYY年M月'),
      list: []
    };
  },
  computed: {
    monthList() {
      const list = [];

      let currentYear = dayjs().year();
      let currentMonth = dayjs().month() + 1;
      let currentDate = dayjs().date();
      for (let i = 0; i < 6; i++) {
        let month = currentMonth - i;
        let year = currentYear;
        if (month <= 0) {
          month = 12 + month;
          year = year - 1;
        }

        list.push({
          label: `${year}年${month}月`,
          value: `${year}${month < 10 ? '0' + month : month}`
        });
      }
      // 当当前日期为21号及以后时,列表增加下月
      if (currentDate >= 21) {
        // 当当前月份的下月没有跨年,则直接增加
        if (currentMonth < 13) {
          list.unshift({
            label: `${currentYear}年${currentMonth + 1}月`,
            value: `${currentYear}${
              currentMonth + 1 < 10
                ? '0' + (currentMonth + 1)
                : currentMonth + 1
            }`
          });
        } else {
          // 当当前月份的下月跨年,则年份加1
          list.unshift({
            label: `${currentYear + 1}年1月`,
            value: `${currentYear + 1}01`
          });
        }
      }
      console.log(currentMonth, 'currentMonth');
      console.log(currentDate, 'currentDate');
      console.log(list, 'list');

      return list;
    },
    params() {
      return (
        this.date.split('年')[0] + '-' + this.date.split('年')[1].split('月')[0]
      );
    }
  },
  async created() {
    await this.getList();
  },
  mounted() {},
  methods: {
    async onConfirmMonth(item) {
      this.date = item.label;
      this.isShowMonthDialog = false;
      await this.getList();
    },
    async getList() {
      try {
        this.show = true;
        const res = await getPayMentRecords({
          classId: this.classId,
          date: this.params
        });
        this.list = res.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.show = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  padding-top: 44px;
  .select-type {
    height: 50px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 13px 16px;
    .button {
      height: 24px;
      display: flex;
      align-items: center;
      padding: 0 1px;
      color: #666666;
      font-size: 18px;
      font-weight: 400;
      img {
        width: 19px;
        height: 19px;
        margin-left: 4px;
      }
    }
  }
  .record-list {
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
    .record {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 5px 15px 0 #00000014;

      // 使用grid布局使head和list垂直对齐
      .head,
      .body .list {
        display: grid;
        grid-template-columns: repeat(3, 1fr); // 根据列数进行分配
        /* gap: 10px; */
        align-items: center; // 垂直居中对齐
        padding: 14px 12px;
        color: #333333;
        font-size: 16px;
      }

      .head {
        font-weight: 500;
        position: relative;
        .head-title {
          /* 居中 */
          display: flex;
          justify-content: center;
        }
      }

      .head::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        transform: scaleY(0.5);
        background-color: #e5e5e5;
      }

      .body {
        .list {
          font-weight: 400;
          .list-item {
            /* 居中 */
            display: flex;
            justify-content: center;
          }
        }
      }
      // 针对日期列不换行
      .head-title:nth-child(2),
      .list-item:nth-child(2) {
        white-space: nowrap; // 防止换行
      }
      .head-title:nth-child(3),
      .list-item:nth-child(3) {
        white-space: nowrap; // 防止换行
      }
    }
  }
}

::v-deep .van-picker__toolbar {
  height: 50px;
  background-color: #f1f1f1;
  .van-picker__cancel {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    font-family: 'PingFangSC-Regular, sans-serif';
  }
  .van-picker__confirm {
    font-size: 16px;
    font-weight: 500;
    color: #ffa900;
    font-family: 'PingFangSC-Regular, sans-serif';
  }
}

.abnormal {
  color: #ed3944;
}
.normal {
  color: #00b893;
}
</style>
