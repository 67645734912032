<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">出入记录</div>
      <div class="nav_left_btn" @click="toHome">
        <img :src="cancel" alt width="15" height="25" />
      </div>
    </div>
    <div class="content">
      <van-list
        v-if="groupList && groupList.length > 0"
        v-model="loading"
        class="dates"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-for="(item, index) in groupList" :key="index" class="card">
          <div class="date">{{ item.date }}</div>
          <div
            v-for="(record, indexs) in item.list"
            :key="indexs"
            class="time-status"
          >
            <div class="time">{{ record.time }}</div>
            <van-tag
              v-if="record.currTemperature && record.currTemperature < 37.3"
              plain
              type="success"
            >
              {{ record.currTemperature }}
            </van-tag>
            <van-tag
              v-if="record.currTemperature && record.currTemperature >= 37.3"
              plain
              type="danger"
            >
              {{ record.currTemperature }}
            </van-tag>
            <div class="time" @click="previewImageFn(record.picture)">
              <img
                :src="record.picture ? record.picture : none"
                width="20rem"
              />
            </div>
            <div
              :class="[
                'status',
                record.door === 1 ? 'statusEnter' : 'statusOut'
              ]"
            >
              {{ record.door === 1 ? '进门' : '出门' }}
            </div>
          </div>
        </div>
      </van-list>
      <div v-else style="padding-top: 6rem">
        <van-empty description="暂无数据" />
      </div>
    </div>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import { getList } from '@/api/access';
import { ImagePreview } from 'vant';
import cancel from '@/assets/cancel.png';
import none from '@/assets/images/photo_none.png';
export default {
  name: 'AccessRecord',
  data() {
    return {
      show: false,
      pagination: {
        current: 1,
        size: 20,
        total: 0
      },
      loading: false,
      finished: false,
      list: [],
      cancel,
      none
    };
  },
  computed: {
    groupList() {
      const { list } = this;
      const data = {};
      list.forEach((item) => {
        const dateArr = item.dateTime.split(' ');
        console.log(dateArr, 'dateArr');

        const date = dateArr[0];
        const time = dateArr[1].substr(0, 8);
        if (!data[date]) {
          data[date] = { date, list: [{ ...item, time }] };
        } else {
          data[date].list.push({ ...item, time });
        }
      });
      console.log(data);
      console.log(Object.values(data));
      return Object.values(data);
    }
  },
  created() {
    const { fetch } = this;
    fetch();
  },
  methods: {
    async fetch() {
      const { list, pagination } = this;
      list.length === 0 && (this.show = true);
      const { code, data, message } = await getList(pagination);
      console.log('出入记录', data);
      if (code === 0) {
        const { records, current, size, total } = data;
        this.list = [...list, ...records];
        this.pagination = { current, size, total };
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        this.show = false;
      } else {
        console.log(message);
      }
    },
    toHome() {
      this.$router.back();
    },
    onLoad() {
      const {
        pagination: { current, size, total },
        fetch
      } = this;
      console.log(current, size, total);
      if (current * size >= total) {
        this.finished = true;
      } else {
        this.pagination = { current: current + 1, size, total };
        fetch();
      }
    },
    previewImageFn(data) {
      ImagePreview([data ? data : none]);
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .content {
    padding-top: 4rem;
    .dates {
      padding: 0 1rem;
      .card {
        background-color: #fff;
        border-radius: 1rem;
        margin-bottom: 1rem;
        padding: 1.125rem;
        box-shadow: 0 0 0.875rem #dcdcdc;
        .date {
          font-weight: 500;
          border-left: 0.375rem solid #f56c6c;
          padding-left: 0.625rem;
          //margin-bottom: 0.3125rem;
        }
        .time-status {
          border-bottom: 1px dashed #ccc;
          //padding: 0.3125rem 1rem;
          padding: 0.3125rem 1.75rem 0.3rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .time {
            font-size: 0.95rem;
          }
          .status {
            border-radius: 0.3125rem;
            font-size: 0.875rem;
            padding: 0 0.125rem;
          }
          .statusEnter {
            //border: 1px solid #07c160;
            color: #07c160;
          }
          .statusOut {
            //border: 1px solid #FA5050;
            color: #fa5050;
          }
        }
      }
    }
    .next {
      margin-top: 20px;
    }
  }
}
::v-deep .el-select .el-input.is-focus .el-input__inner {
  border-color: #c0c4cc;
}
::v-deep .van-cell {
  font-size: 1rem;
  justify-content: space-between;
}
::v-deep .van-cell__value {
  flex: 0 0 auto;
}
::v-deep .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
