<template>
  <div class="container">
    <HeadTitle title="健康饮食" return-path="Center"></HeadTitle>
    <div v-for="item in list" :key="item.classId" class="content">
      <div class="class-name">{{ item.mealExpense.className }}</div>
      <div class="content-item">
        <div class="title">
          <div class="icon">食</div>
          <div class="name">饮食检测</div>
        </div>
        <div class="body">
          <div
            class="body-item"
            @click="toPage('StudentList', '班级名单', item.mealExpense.classId)"
          >
            <div class="name">班级名单</div>
            <div class="count">
              <span>{{ item.monitor.totalStus }}</span>
              <img src="../../assets/images/health-arrow.png" alt="" />
            </div>
          </div>
          <div class="border"></div>
          <div
            class="body-item"
            @click="toPage('StudentList', '异常名单', item.mealExpense.classId)"
          >
            <div class="name">异常名单</div>
            <div class="count">
              <span class="abnormal">{{ item.monitor.totalMonitor }}</span>
              <img src="../../assets/images/health-arrow.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="title">
          <div class="icon">缴</div>
          <div class="name">费用缴纳</div>
        </div>
        <div class="body">
          <div
            class="pay-item"
            @click="toPage('PayList', '缴费名单', item.mealExpense.classId)"
          >
            <div class="name">缴费名单</div>
            <img src="../../assets/images/health-arrow.png" alt="" />

            <!-- <div class="count">
              <span>
                {{ item.mealExpense.buyNum }}/{{
                  item.mealExpense.buyNum + item.mealExpense.notBuyNum
                }}
              </span>
            </div> -->
          </div>
          <div class="border"></div>
          <div class="pay-item">
            <div
              class="name"
              @click="toPage('PayRecord', '缴费记录', item.mealExpense.classId)"
            >
              缴费记录
            </div>
            <img src="../../assets/images/health-arrow.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import HeadTitle from '@/components/headTitle/index.vue';
import { getPayMentList } from '@/api/health-meal.js';
export default {
  components: { HeadTitle },
  data() {
    return {
      list: [],
      show: false
    };
  },
  async created() {
    await this.getList();
  },
  mounted() {},
  methods: {
    toPage(path, title, classId) {
      this.$router.push({
        name: path,
        query: {
          title,
          classId
        }
      });
    },
    async getList() {
      try {
        this.show = true;
        const res = await getPayMentList();
        this.list = res.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.show = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 54px;
  box-sizing: border-box;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    .class-name {
      color: #333333;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 12px;
      text-align: left;
      line-height: 16px;
      width: 100%;
      padding-left: 16px;
      box-sizing: border-box;
    }
    .content-item:last-child {
      margin-bottom: 0;
    }
    .content-item {
      width: 343px;
      height: 189px;
      border-radius: 10px;
      background-color: #fff;
      margin-bottom: 15px;
      box-shadow: 0 5px 15px 0 #00000014;
      box-sizing: border-box;
      .title {
        display: flex;
        align-items: center;
        height: 50px;
        position: relative;
        margin-left: 16px;
        .icon {
          width: 21px;
          height: 21px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #00b893;
          color: #ffffff;
          font-size: 13px;
          font-weight: 500;
        }
        .name {
          color: #333333;
          font-size: 16px;
          font-weight: 700;
          margin-left: 4px;
        }
      }
      .title::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #e5e5e5;
        transform: scaleY(0.5);
        bottom: 0;
        left: 0;
      }
      .body {
        display: flex;
        justify-content: space-between;
        margin-top: 44px;
        padding: 0 50px;
        box-sizing: border-box;
        align-items: center;
        .body-item {
          height: 46px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          .name {
            color: #666666;
            font-size: 14px;
            font-weight: 500;
            img {
              margin-left: 6px;
              width: 12px;
              height: 12px;
            }
          }
          .abnormal {
            color: #ffa900;
          }
          .count {
            color: #333333;
            font-size: 16px;
            font-weight: 700;
            img {
              margin-left: 6px;
              width: 12px;
              height: 12px;
            }
          }
        }
        .pay-item {
          color: #666666;
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;
          img {
            width: 12px;
            height: 12px;
            margin-left: 6px;
          }
        }
        .border {
          width: 1px;
          height: 24px;
          background-color: #e5e5e5;
          transform: scaleX(0.5);
        }
      }
    }
  }
}
</style>
