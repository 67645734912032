<template>
  <div style="display: flex; flex-direction: column; width: 100%; height: 100%">
    <topHeader title="我的收货地址"></topHeader>
    <div
      style="
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
      "
    >
      <div style="margin: 0px 16px; width: 100%">
        <van-button
          block
          style="
            background: #fa5050;
            color: #fff;
            font-size: 18px;
            border-radius: 4px;
          "
          @click="toAddAddress('')"
        >
          添加地址
        </van-button>
      </div>
    </div>
    <div style="flex: 1; overflow: auto; box-sizing: border-box; padding: 16px">
      <div
        v-for="(item, index) in addressList"
        :key="index"
        style="
          height: 145px;
          background: #fff;
          margin-bottom: 15px;
          border-radius: 10px;
        "
        @click="setAddress(item)"
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 16px;
            box-sizing: border-box;
            padding: 16px;
          "
        >
          <span style="color: #333; margin-right: 12px">{{ item.name }}</span>
          <span style="color: #666; margin-right: 12px">
            {{ item.addressPhone }}
          </span>
          <span
            v-if="item.isDefault === 1"
            style="
              background: #fa5050;
              color: #fff;
              font-size: 12px;
              padding: 2px 8px;
              border-radius: 4px;
            "
          >
            默认
          </span>
        </div>
        <div
          style="
            color: #666;
            font-size: 16px;
            box-sizing: border-box;
            padding: 0 16px 16px;
          "
        >
          {{ item.address }}
        </div>
        <CommonBorder></CommonBorder>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 16px;
          "
        >
          <van-radio-group v-model="item.isDefault">
            <van-radio
              checked-color="#fa5050"
              icon-size="16px"
              :name="1"
              @click.stop.native="updateAddressDefaultFn(item.id)"
            >
              <span style="font-size: 16px; color: #666">默认</span>
            </van-radio>
          </van-radio-group>
          <div
            style="
              font-size: 16px;
              color: #666;
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 20px;
              vertical-align: bottom;
            "
          >
            <img
              style="width: 18px; height: 18px; margin-right: 5px"
              src="@/assets/images/editImg.png"
              alt=""
            />
            <span @click.stop="toAddAddress(item)">编辑</span>
            <img
              v-if="item.isDefault !== 1"
              style="
                width: 18px;
                height: 18px;
                margin-right: 5px;
                margin-left: 12px;
              "
              src="@/assets/images/deleteImg.png"
              alt=""
            />
            <span
              v-if="item.isDefault !== 1"
              @click.stop="deleteAddressFn(item.id)"
            >
              删除
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import right from '@/assets/right.png';
import dish from '@/assets/dish.png';
import cancel from '@/assets/cancel.png';
import {
  getAddressListAPI,
  updateAddressDefaultAPI,
  deleteAddressAPI
} from '@/api/order';
import { Toast } from 'vant';
import topHeader from '@/components/topHeader';
import CommonBorder from '@/components/commonBorder';

export default {
  components: { topHeader, CommonBorder },
  data() {
    return {
      dish,
      cancel,
      apiURL: process.env.VUE_APP_BASE_API,
      right,
      order: {},
      user: {},
      phone: '',
      organizationId: '',
      addressList: [],
      isWhite: ''
    };
  },
  async created() {
    this.order = JSON.parse(this.$route.query.order);
    this.user = JSON.parse(sessionStorage.getItem('personInfo'));
    this.phone = sessionStorage.getItem('phone');
    this.organizationId = sessionStorage.getItem('organizationId');
    this.isWhite = sessionStorage.getItem('isWhite');
    if (!this.user && !this.organizationId && !this.phone) {
      this.$router.push({ name: 'OrderLogin' });
    }
    await this.getAddressListFn();
  },
  methods: {
    setAddress(item) {
      console.log(item, 'item');

      this.$store.commit('user/SET_ADDRESS_DEFAULT', item);

      this.$router.go(-1);

      // 已切换地址
      Toast.success('已切换地址');
    },
    toAddAddress(item) {
      if (item) {
        this.$router.push({
          name: 'OrderAddAddress',
          query: { item: JSON.stringify(item) }
        });
      } else {
        this.$router.push({
          name: 'OrderAddAddress',
          query: { order: JSON.stringify(this.order) }
        });
      }
    },
    async getAddressListFn() {
      const res = await getAddressListAPI({
        phone: this.phone ? this.phone : '',
        userId: this.user ? this.user.id : '',
        organizationId: this.user
          ? this.user.organization.id
          : this.organizationId
      });
      if (res.code === 0) {
        this.addressList = res.data.sort((a, b) => b.isDefault - a.isDefault);
        this.$set(this, 'addressList', this.addressList);
      }
    },

    async updateAddressDefaultFn(id) {
      const res = await updateAddressDefaultAPI({
        phone: this.phone ? this.phone : '',
        userId: this.user ? this.user.id : '',
        organizationId: this.user
          ? this.user.organization.id
          : this.organizationId,
        id
      });
      if (res.code === 0) {
        Toast('设置成功!');
        this.getAddressListFn();
      }
    },
    async deleteAddressFn(id) {
      const res = await deleteAddressAPI(id);
      if (res.code === 0) {
        Toast('删除成功!');
        this.getAddressListFn();
      }
    },

    toBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped></style>
