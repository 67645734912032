<template>
  <div class="container">
    <HeadTitle
      title="缴费情况"
      :query="{
        classId
      }"
      return-path="PayList"
    ></HeadTitle>
    <div class="count">
      <div class="num">¥{{ detail.totalAmount / 100 }}</div>
      <div class="unit">缴费金额(元)</div>
    </div>
    <div class="people">
      <div class="success">
        <div class="num">{{ detail.totalSuccessNum }}</div>
        <div class="title">缴费成功人数</div>
      </div>
      <div class="border"></div>
      <div class="fail">
        <div class="num" style="color: #ed3944">
          {{ detail.totalFailedNum }}
        </div>
        <div class="title">缴费失败人数</div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadTitle from '@/components/headTitle/index.vue';

export default {
  components: { HeadTitle },
  data() {
    return {
      classId: this.$route.query.classId,
      detail: this.$route.query.detail
    };
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.container {
  .count {
    height: 200px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 56px;
    box-sizing: border-box;
    margin-bottom: 15px;
    .num {
      color: #333333;
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .unit {
      color: #666666;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .people {
    height: 154px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 59px 50px 49px 50px;
    background-color: #fff;
    .success,
    .fail {
      height: 46px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .num {
        color: #333333;
        font-size: 16px;
        font-weight: 700;
      }
      .title {
        color: #666666;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .border {
      width: 1px;
      height: 24px;
      background-color: #e5e5e5;
      transform: scaleX(0.5);
    }
  }
}
</style>
