<template>
  <div class="container">
    <HeadTitle :title="title" return-path="HealthMeal"></HeadTitle>
    <div class="student-list">
      <div
        v-for="(student, index) in studentList"
        :key="index"
        class="student-item"
        @click="
          toPage('MealRecord', {
            title,
            classId,
            userId: student.id
          })
        "
      >
        <img
          v-if="student.photo"
          class="student-photo"
          :src="`${apiURL}/image?name=${student.photo}`"
        />
        <img
          v-else
          class="student-photo"
          src="../../../assets/images/default-student.png"
        />
        <div class="student-name">{{ student.name }}</div>
        <div
          v-if="student.dietaryMonitorFlag === true"
          class="student-click"
        ></div>
      </div>
    </div>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import HeadTitle from '@/components/headTitle/index.vue';
import { getClassAbnormalList, getClassList } from '@/api/health-meal';
export default {
  components: { HeadTitle },
  data() {
    return {
      title: '',
      studentList: [],
      classId: this.$route.query.classId,
      show: false,
      organizationId: JSON.parse(sessionStorage.getItem('personInfo'))
        .organization.id,
      apiURL: process.env.VUE_APP_BASE_API
    };
  },
  async created() {
    this.title = this.$route.query.title;
    await this.getList();
  },
  mounted() {},
  methods: {
    async getList() {
      try {
        this.show = true;
        const res = await (this.title === '异常名单'
          ? getClassAbnormalList({
              classId: this.classId,
              organizationId: this.organizationId
            })
          : getClassList({
              classId: this.classId,
              organizationId: this.organizationId
            }));
        this.studentList = res.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.show = false;
      }
    },
    toPage(name, query) {
      this.$router.push({
        name,
        query
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  .student-list {
    margin-top: 54px;
    display: flex;
    /* 一排四个 */
    flex-wrap: wrap;
    .student-item {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-bottom: 21px;
      .student-photo {
        width: 70px;
        height: 70px;
        border-radius: 4px;
        margin-bottom: 12px;
      }
      .student-name {
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        font-family: '苹方';
      }
      .student-click {
        width: 14px;
        height: 14px;
        opacity: 1;
        background: #f00703;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
