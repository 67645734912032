<template>
  <div class="container">
    <HeadTitle
      title="就餐记录"
      return-path="StudentList"
      :query="{
        title,
        classId
      }"
    ></HeadTitle>
    <div class="record-list">
      <div class="record">
        <div class="head">
          <div class="head-title">日期</div>
          <div class="head-title">餐段</div>
          <div class="head-title">消费次数</div>
          <div class="head-title">消费金额</div>
        </div>
        <div class="body">
          <div
            v-for="item in list"
            :key="item.date + item.mealSection"
            class="list"
            :class="item.num === 0 ? 'error' : ''"
          >
            <div class="list-item">{{ item.date }}</div>
            <div class="list-item">
              {{ item.mealSection }}
            </div>
            <div class="list-item">{{ item.num }}</div>
            <div class="list-item">¥ {{ item.totalAmount / 100 }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import HeadTitle from '@/components/headTitle/index.vue';
import {
  getPayRecordsDetail,
  getPayRecordsDetailAbnormal
} from '@/api/health-meal';

export default {
  components: { HeadTitle },
  data() {
    return {
      title: '',
      classId: this.$route.query.classId,
      show: false,
      organizationId: JSON.parse(sessionStorage.getItem('personInfo'))
        .organization.id,
      userId: this.$route.query.userId,
      list: []
    };
  },
  async created() {
    this.title = this.$route.query.title;
    await this.getList();
  },
  mounted() {},
  methods: {
    async getList() {
      try {
        this.show = true;
        const res = await (this.title === '异常名单'
          ? getPayRecordsDetailAbnormal({
              userId: this.userId,
              organizationId: this.organizationId
            })
          : getPayRecordsDetail({
              userId: this.userId,
              organizationId: this.organizationId
            }));
        this.list = res.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.show = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  .record-list {
    margin-top: 54px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
    .record {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 5px 15px 0 #00000014;

      // 使用grid布局使head和list垂直对齐
      .head,
      .body .list {
        display: grid;
        grid-template-columns: repeat(4, 1fr); // 根据列数进行分配
        /* gap: 10px; */
        align-items: center; // 垂直居中对齐
        padding: 14px 12px;
        color: #333333;
        font-size: 16px;
      }

      .head {
        font-weight: 500;
        position: relative;
        .head-title {
          /* 居中 */
          display: flex;
          justify-content: center;
        }
      }

      .head::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        transform: scaleY(0.5);
        background-color: #e5e5e5;
      }

      .body {
        .list {
          font-weight: 400;
          .list-item {
            /* 居中 */
            display: flex;
            justify-content: center;
          }
        }
      }
      // 针对日期列不换行
      .head-title:nth-child(1),
      .list-item:nth-child(1) {
        white-space: nowrap; // 防止换行
      }
    }
  }
  .error {
    color: #ed3944 !important;
  }
}
</style>
