<template>
  <div>
    <topHeader title="添加收货地址"></topHeader>
    <van-form :show-error="false" style="margin-top: 10px">
      <van-field
        v-model="submitData.name"
        name="收货人"
        label="收货人"
        placeholder="请输入收货人"
        input-align="left"
        :rules="[{ required: true, message: '请填写收货人' }]"
        @input="inputRulesFn('name')"
      />
      <CommonBorder></CommonBorder>
      <van-field
        v-model="submitData.addressPhone"
        name="手机号码"
        label="手机号码"
        placeholder="请输入手机号码"
        input-align="left"
        :rules="[{ required: true, message: '请填写手机号码' }]"
        @input="inputRulesFn('addressPhone')"
      />
      <CommonBorder></CommonBorder>
      <van-field
        v-model="submitData.address"
        name="详细地址"
        label="详细地址"
        placeholder="请输入详细地址"
        input-align="left"
        maxlength="30"
        :rules="[{ required: true, message: '请填写详细地址' }]"
      />
      <CommonBorder></CommonBorder>
      <div style="margin: 30px 16px">
        <van-button
          block
          style="
            background: #fa5050;
            color: #fff;
            font-size: 18px;
            border-radius: 4px;
          "
          @click="submitAddress"
        >
          保存地址
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import right from '@/assets/right.png';
import { addAddressAPI, updateAddressAPI } from '@/api/order';
import { Toast } from 'vant';
import cancel from '@/assets/cancel.png';
import topHeader from '@/components/topHeader';
import CommonBorder from '@/components/commonBorder';

export default {
  components: { topHeader, CommonBorder },
  data() {
    return {
      apiURL: process.env.VUE_APP_BASE_API,
      cancel,
      right,
      order: {},
      submitData: {
        name: '',
        phone: '',
        address: '',
        addressPhone: '',
        userId: '',
        organizationId: ''
      },
      action: '',
      organizationId: '',
      phone: '',
      isWhite: ''
    };
  },
  created() {
    this.user = JSON.parse(sessionStorage.getItem('personInfo'));
    this.phone = sessionStorage.getItem('phone');
    this.organizationId = sessionStorage.getItem('organizationId');
    this.isWhite = sessionStorage.getItem('isWhite');
    if (!this.user && !this.organizationId && !this.phone) {
      this.$router.push({ name: 'OrderLogin' });
      return;
    }
    if (this.$route.query.item) {
      this.submitData = JSON.parse(this.$route.query.item);
      this.action = 'edit';
    } else {
      this.submitData = {
        name: '',
        phone: this.user ? this.user.phone : this.phone,
        address: JSON.parse(this.$route.query.order).address,
        addressPhone: '',
        userId: this.user ? this.user.id : '',
        organizationId: this.user
          ? this.user.organization.id
          : this.organizationId
      };
      this.action = 'add';
    }
  },
  methods: {
    submitAddress() {
      const { name, addressPhone, address } = this.submitData;
      if (!name || !addressPhone || !address) {
        return;
      }
      if (address.trim() === '') {
        Toast('地址为空!');
        return;
      }
      if (this.action === 'add') {
        addAddressAPI(this.submitData).then((res) => {
          if (res.code === 0) {
            Toast('添加成功!');
            this.$router.back();
          }
        });
      } else {
        updateAddressAPI(this.submitData).then((res) => {
          if (res.code === 0) {
            Toast('编辑成功!');
            this.$router.back();
          }
        });
      }
    },
    toBack() {
      this.$router.back();
    },
    inputRulesFn(val) {
      if (val === 'name') {
        this.submitData.name = this.submitData.name.replace(
          /[`~!#$%^&*()_\+=<>?:"{}|~！#￥%……&*（）={}|《》？：“”【】、；‘’，。、\s+]/g,
          ''
        );
      } else if (val === 'addressPhone') {
        this.submitData.addressPhone = this.submitData.addressPhone.replace(
          /\D/g,
          ''
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
