import request from '@/utils/request';
import store from '../store';
// 采集人脸上传的接口
export function onloadImage(data) {
  return request({
    url: '/upload/avatar',
    method: 'post',
    timeout: 30000,
    data
  });
}

export function login(data) {
  return request({
    url: '/icbc/bind',
    method: 'post',
    data
  });
}

export function queryEmployee() {
  return request({
    url: `/icbc/auth/user`,
    method: 'get'
  });
}

// 更新人脸的接口
export function updateEmployeePhoto(data) {
  const userId = store.getters.userId;
  return request({
    url: `/icbc/client-users/${userId}/photo`,
    method: 'post',
    data
  });
}

export function getPayCode(params) {
  return request({
    url: `/icbc/client-users/${params.id}/pay-code`,
    method: 'get'
  });
}

export function getHistory(params) {
  return request({
    url: `/recharges?userId=${params.id}&current=${params.current}&size=${params.size}&status=${params.status}&startDate=${params.startDate}&endDate=${params.endDate}`,
    method: 'get'
  });
}
export function getDetail(params) {
  return request({
    url: `/icbc/consumption-records?userId=${params.id}&current=${
      params.current
    }&size=${params.size}${
      '&startTime=' + params.startDate + '&endTime=' + params.endDate
    }${params.type !== '' ? '&type=' + params.type : ''}`,
    method: 'get'
  });
}

export function identifyingCode(data) {
  return request({
    url: `/verify-code`,
    method: 'post',
    data
  });
}

export function getOrgList(params) {
  return request({
    url: `/icbc/organizations/by-id-card`,
    method: 'get',
    params
  });
}
export function getOrgByMobile(params) {
  return request({
    url: `/icbc/organizations/byMobile`,
    method: 'get',
    params
  });
}

export function getUserList(params) {
  return request({
    url: `/abc/organizations/userListByMobile`,
    method: 'get',
    params
  });
}

export function getToken(data) {
  return request({
    url: `/icbc/auth/login`,
    method: 'post',
    data
  });
}

export function queryBanners() {
  return request({
    url: `/icbc/bank/image`,
    method: 'get'
  });
}

export function getAccInfo(params) {
  return request({
    url: `/icbc/auth/user`,
    method: 'get',
    params
  });
}

// 查询该电话号码是否为白名单
// api/icbc/organizations/byMobile?phone=15010053668
export function getPhoneNumber(phone) {
  return request({
    url: `/icbc/organizations/byMobile?phone=${phone}`,
    method: 'get'
  });
}

// 充值时间限制   /icbc/h5/limit
export function getLimitAPI(id) {
  return request({
    url: `/icbc/h5/limit?organizationId=${id}`,
    method: 'get'
  });
}

// 获取应用配置   /h5/user/module
export function getUserConfigAPI(id) {
  return request({
    url: `/h5/user/module?userId=${id}`,
    method: 'get'
  });
}

// 编辑用户应用配置    /h5/user/module
export function editUserConfigAPI(data) {
  return request({
    url: `/h5/user/module`,
    method: 'post',
    data
  });
}

// h5数字人民币充值接口  /icbc/h5/dcepPay
export function h5DcepPayAPI(amount, userId) {
  return request({
    url: `/icbc/h5/dcepPay?amount=${Math.floor(amount * 100)}&userId=${userId}`,
    method: 'get'
  });
}

// h5数字人民币活动充值接口  /icbc/h5/dcepPay
export function h5DcepPayInActivityAPI(amount, userId, activityId) {
  return request({
    url: `/icbc/h5/dcepPayInActivity?amount=${Math.floor(
      amount * 100
    )}&userId=${userId}&activityId=${activityId}`,
    method: 'get'
  });
}

// h5数字人民币充值接口  /icbc/h5/dcepPay
export function h5PayAPI(amount, userId) {
  return request({
    url: `/icbc/h5/pay?amount=${Math.floor(amount * 100)}&userId=${userId}`,
    method: 'get'
  });
}

// h5数字人民币活动充值接口  /icbc/h5/dcepPay
export function h5PayInActivityAPI(amount, userId, activityId) {
  return request({
    url: `/icbc/h5/payInActivity?amount=${Math.floor(
      amount * 100
    )}&userId=${userId}&activityId=${activityId}`,
    method: 'get'
  });
}

// 微信登录接口  /code/login post
export function weixinLoginAPI(data) {
  return request({
    url: `/code/login`,
    method: 'post',
    data
  });
}

// 微信登录获取token   /weixin/accesstoken
export function weixinGetTokenAPI(appid, code) {
  return request({
    url: `/weixin/accesstoken?appid=${appid}&code=${code}`,
    method: 'get'
  });
}
// 微信支付 weixin/jsapipay
export function weixinPaymentAPI(data) {
  return request({
    url: `/weixin/jsapi/pay`,
    method: 'post',
    data
  });
}

export function weixinPaymentAPI1(data) {
  return request({
    url: `/weixin/jsapi/pay1`,
    method: 'post',
    data
  });
}

// 查询是否在更新系统   /admin/system/setting
export function getSystemSettingAPI() {
  return request({
    url: `/h5/system/setting`,
    method: 'get'
  });
}

// 微信登录获取token   /weixin/accesstoken
export function getPayTypeAPI(outTradeNo) {
  return request({
    url: `/user/h5/payType?outTradeNo=${outTradeNo}`,
    method: 'get'
  });
}

export function batchRefundAPI(id) {
  return request({
    url: `/user/userRefundList?organizationId=${id}`,
    method: 'get',
    timeout: 6000000
  });
}

export function h5BatchRefundAPI(data) {
  return request({
    url: `/h5/refund`,
    method: 'post',
    timeout: 6000000,
    data
  });
}

// 获取商户系统设置接口
export function getUserSystemSetupAPI(id) {
  return request({
    url: `/organization/setting/${id}`,
    method: 'get'
  });
}

// // 首页返回   /abc/organizations/labelUser?phone=15112526221&type=2
// export function labelUserAPI(phone) {
//   return request({
//     url: `/abc/organizations/labelUser?phone=${phone}&type=2`,
//     method: 'get'
//   });
// }
