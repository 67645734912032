import axios from 'axios'
import { Toast, Notify } from 'vant'
import { filter } from '@/utils/util'
import router from '../router'
import store from '../store'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // const token = window.sessionStorage.getItem('token')
    const { token } = store.getters
    if (token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + token

    }
    config.params = filter(config.params)
    config.data = filter(config.data)
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code === 401) {
      if (router.history.current.name === 'Home') {
        router.go(0)
      } else {
        router.push({ name: 'Home' })
      }
      return
    }
    if (res.code && res.code !== 0) {
      Toast.fail({
        message: res.message || 'Error',
        duration: 5 * 1000
      })
    }
    return res
  },
  error => {
    switch (error.response.status) {
      case 400:
        // Message.error(error.response.data.message)
        break
      case 401:
        // Notify({ type: 'danger', message: '未登录或登录已过期' })
        if (router.history.current.name === 'Home') {
          router.go(0)
        } else {
          router.push({ name: 'Home' })
        }
        break
      case 404:
        // Message.error('访问的资源不存在')
        Notify({ type: 'danger', message: '访问的资源不存在' })
        break
      case 405:
        // Message.error('API请求类型错误')
        Notify({ type: 'danger', message: 'API请求类型错误' })

        break
      case 422:
        // Message.error(error.response.data.message)
        Notify({ type: 'danger', message: `error.response.data.message` })
        break
      case 500:
        // Message.error('系统错误')
        Notify({ type: 'danger', message: '系统错误' })

        break
      default:
        // Message({
        //   message: error.message,
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        break
    }
    return Promise.reject(error)
  }
)

export default service
