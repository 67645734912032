<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">充值中心</div>
      <div class="nav_left_btn" @click="toHome">
        <img :src="cancel" alt width="15" height="25" />
      </div>
    </div>
    <div v-if="!activityFlag" class="acc">
      <div v-show="activeTitle === 'Order'">
        <div class="acc-auto">
          <div
            class="recharge-num"
            :class="{ recharge_num_active: moneyO === 100 }"
            @click="toRecharge(1)"
          >
            100元
          </div>
          <div
            class="recharge-num"
            :class="{ recharge_num_active: moneyO === 200 }"
            @click="toRecharge(2)"
          >
            200元
          </div>
          <div
            class="recharge-num"
            :class="{ recharge_num_active: moneyO === 500 }"
            @click="toRecharge(3)"
          >
            500元
          </div>
        </div>
        <div class="account">
          <span class="cell_li_left">金额</span>
          <input
            v-model="moneyO"
            type="number"
            placeholder="请输入其他金额进行充值"
            class="input1-acc"
            onfocus="this.style.border='none'"
          />
        </div>
        <a :class="disableButton ? 'limit' : ''" @click="isOnSubmitFn">
          <div class="to-pay">
            <div :class="disableButton ? 'normal_btn active' : 'normal_btn'">
              立即充值
            </div>
          </div>
        </a>
      </div>
    </div>

    <div v-if="activityFlag" class="acc" style="margin-top: 10px">
      <div v-show="activeTitle === 'Order'">
        <!-- <div style="padding: 10px;background: #fff;box-sizing: border-box;">
          <el-radio
            v-model="radio"
            label="1"
            @change="changeObject(1)"
          >给自己充值</el-radio>
          <el-radio
            v-model="radio"
            label="2"
            @change="changeObject(2)"
          >代他人充值</el-radio>
          <div class="tel">
            <span class="text">手机号码</span>
            <input
              v-model="phone"
              type="number"
              placeholder="请输入手机号码"
              class="phone"
            >
          </div>
        </div>-->
        <div class="acc-auto1">
          <div
            class="recharge-num"
            :class="{ recharge_num_active: money === 50 }"
            style="
              display: flex;
              justify-content: space-around;
              flex-direction: column;
            "
            @click="toRechargeAct(1)"
          >
            <span style="margin-top: 8px">50元</span>
            <span style="font-size: 12px">
              实际到账{{ totalMoney['50'] }}元
            </span>
          </div>
          <div
            class="recharge-num"
            :class="{ recharge_num_active: money === 100 }"
            style="
              display: flex;
              justify-content: space-around;
              flex-direction: column;
            "
            @click="toRechargeAct(2)"
          >
            <span style="margin-top: 8px">100元</span>
            <span style="font-size: 12px">
              实际到账{{ totalMoney['100'] }}元
            </span>
          </div>
          <div
            class="recharge-num"
            :class="{ recharge_num_active: money === 200 }"
            style="
              display: flex;
              justify-content: space-around;
              flex-direction: column;
            "
            @click="toRechargeAct(3)"
          >
            <span style="margin-top: 8px">200元</span>
            <span style="font-size: 12px">
              实际到账{{ totalMoney['200'] }}元
            </span>
          </div>
        </div>
        <div class="account">
          <span class="cell_li_left">金额</span>
          <input
            v-model="money"
            type="number"
            placeholder="请输入其他金额进行充值"
            class="input1-acc"
            @input="inputMoney"
          />
        </div>
        <div v-if="money" class="details">
          <div class="detail-item">
            <span class="title">实际支付金额：</span>
            <span class="amount">{{ Number(money).toFixed(2) }}元</span>
          </div>
          <div class="detail-item">
            <span class="title">赠送金额：</span>
            <span class="amount">
              {{ Number(getCoupon(money) / 100).toFixed(2) }}元
            </span>
          </div>
          <div>
            <span class="title">到账明细：</span>
            <ul style="margin: 0; padding: 0; list-style: none">
              <li>
                <span>现金钱包到账：</span>
                <span>{{ Number(money).toFixed(2) }}元</span>
              </li>
              <li>
                <span>优惠钱包到账：</span>
                <span>{{ Number(getCoupon(money) / 100).toFixed(2) }}元</span>
              </li>
            </ul>
          </div>
          <div class="detail-item">
            <span class="title">合计到账：</span>
            <span class="amount">
              {{
                Number(Number(money) + Number(getCoupon(money) / 100)).toFixed(
                  2
                )
              }}元
            </span>
          </div>
        </div>
        <div class="description">
          <div>
            <div class="title">优惠说明</div>
            <div style="margin-bottom: 10px">
              <div>
                <span class="subhead">活动时间：</span>
                <span class="subhead">{{ starttime }}至{{ endtime }}</span>
              </div>
              <div>
                <span class="subhead">领取规则：</span>
                <span class="subhead">{{ activityIntro }}</span>
              </div>
              <div>
                <span class="subhead">领取次数：</span>
                <span class="subhead">
                  每个账户活动期间最多享受{{ joinTimes }}次赠送。
                </span>
              </div>
            </div>
          </div>
          <!-- <div>
            <div class="title">温馨提示</div>
            <div class="subhead">本次充值活动因存在赠送金额，赠送金额不支持退款操作</div>
          </div>-->
        </div>
        <a :class="disableButton ? 'limit' : ''" @click="isOnSubmitFn">
          <div class="to-pay">
            <div :class="disableButton ? 'normal_btn active' : 'normal_btn'">
              立即充值（￥{{ Number(money).toFixed(2) }}）
            </div>
          </div>
        </a>
      </div>
    </div>
    <!-- 充值限制提示弹窗 -->
    <van-dialog
      v-model="isShowRechargeLimit"
      class="limit-dialog"
      show-cancel-button
      :show-confirm-button="false"
      cancel-button-color="#FFA900"
    >
      <template #title>
        <div class="title">公告</div>
      </template>
      <div class="body">
        <p>尊敬的用户:</p>
        <p>
          &emsp;由于财务结算时间限制,我司将对充值时间进行调整,具体调整情况如下:
        </p>
        <p>
          &emsp;{{ rechargeType === 0 ? '充值时间' : '不可充值时间' }}为:
          <span style="color: #ed3944">
            {{ rechargeLimitMsg }}
          </span>
          ,请需要充值的用户注意充值时间。
        </p>
        <p>&emsp;特此公告。</p>
        <p style="text-align: right">{{ user?.organization?.zhName }}</p>
        <p style="text-align: right">{{ rechargeUpdateTime }}</p>
      </div>
    </van-dialog>
    <!-- <div class="mask" v-if="limit.isShow">
      <div class="tips">
        <div class="tips_center">
          <h3>公告</h3>
          <span>尊敬的用户:</span>
          <p>
            由于财务结算时间限制，我司将对充值时间进行调整，具体调整情况如下:
          </p>
          <p>
            充值时间为:
            <span style="color: red; word-break: break-all">
              {{ limit.msg }}
            </span>
            ，过时将不能进行充值，请需要充值的用户注意充值时间。
          </p>
          <p>特此公告。</p>
          <div class="tips_right">
            <p>{{ user.organization.zhName }}</p>
            <p>{{ limit.updateDate }}</p>
          </div>
          <div class="cancel" @click="closeFn">x</div>
        </div>
      </div>
    </div> -->
    <van-popup
      v-model="show"
      class="payment_popup"
      position="bottom"
      :style="{ height: '60%' }"
      :close-on-click-overlay="false"
      :round="true"
    >
      <div class="payment_in">
        <div class="payment_top">
          <img :src="PayClose" alt @click="show = false" />
        </div>
        <div class="payment_money">
          <h1>
            {{
              activityFlag
                ? Number(money).toFixed(2)
                : Number(moneyO).toFixed(2)
            }}
          </h1>
          <p>元</p>
        </div>
        <div class="payment_order">
          <p class="left">充值金额</p>
          <p class="right">
            ￥{{
              activityFlag
                ? Number(money).toFixed(2)
                : Number(moneyO).toFixed(2)
            }}
          </p>
        </div>
        <div class="payment_select">
          <van-radio-group v-model="result">
            <van-cell-group>
              <van-cell
                v-if="
                  paymentSetting.icbc &&
                  paymentSetting.icbc.appId !== '' &&
                  paymentSetting.icbc.privateKey !== ''
                "
                title="工银e支付"
                clickable
                @click="result = 1"
              >
                <template #right-icon>
                  <van-radio checked-color="#FA5050" :name="1" />
                </template>
              </van-cell>
              <van-cell
                v-if="
                  paymentSetting.icbcDcep &&
                  paymentSetting.icbcDcep.appId !== '' &&
                  paymentSetting.icbcDcep.privateKey !== ''
                "
                title="数字人民币"
                clickable
                @click="result = 2"
              >
                <template #right-icon>
                  <van-radio checked-color="#FA5050" :name="2" />
                </template>
              </van-cell>
              <van-cell
                v-if="
                  paymentSetting.weixin &&
                  paymentSetting.weixin.appId !== '' &&
                  paymentSetting.weixin.privateKey !== ''
                "
                title="微信支付"
                clickable
                @click="result = 3"
              >
                <template #right-icon>
                  <van-radio checked-color="#FA5050" :name="3" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
        <div class="payment_btn">
          <van-button round :loading="isClick" @click="payFor">
            确认支付
          </van-button>
        </div>
      </div>
    </van-popup>
    <van-dialog
      v-model="isDialog"
      class="dialog_box"
      confirm-button-text="退出"
      title="通知"
      :show-confirm-button="false"
      confirm-button-color="rgba(2,167,240)"
      @confirm="toPage('Login')"
    >
      <div class="dialog_div">系统升级中,请30分钟后再尝试...</div>
    </van-dialog>
    <div v-html="htmlText">{{ htmlText }}</div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import cancel from '@/assets/cancel.png';
import close from '@/assets/close.png';
import moment from 'moment';
import { Toast, Dialog } from 'vant';
import {
  getLimitAPI,
  h5DcepPayAPI,
  h5DcepPayInActivityAPI,
  h5PayAPI,
  h5PayInActivityAPI,
  weixinPaymentAPI,
  getSystemSettingAPI,
  weixinPaymentAPI1
} from '@/api/api';
// import { getActivityList, getVerify, getpayInActivity } from '@/api/preferential'
import { getActivityList, getVerify } from '@/api/preferential';
// import { getActivityList } from '@/api/preferential'
const { VUE_APP_BASE_API } = process.env;
import PayClose from '@/assets/pay_close.png';
export default {
  name: 'Recharge',
  data() {
    return {
      PayClose,
      close,
      isLink: false,
      toStretches: false,
      cancel,
      activeTitle: 'Order',
      money: '',
      moneyO: '',
      num: '',
      consumeList: [],
      h5PayUrl: `${VUE_APP_BASE_API}/icbc/h5/pay`,
      h5PayInActivityUrl: `${VUE_APP_BASE_API}/icbc/h5/payInActivity`,
      coupon: '',
      radio: '1',
      phone: '',
      activityFlag: false,
      activityId: null,
      userId: null,
      activityIntro: '',
      starttime: '',
      endtime: '',
      joinTimes: null,
      activityRule: [{ amount: '', coupon: '' }],
      amountArr: [],
      totalMoney: {
        50: 0,
        100: 0,
        200: 0
      },
      limit: {
        msg: '',
        isShow: false,
        updateDate: '',
        code: ''
      },
      show: false,
      list: [
        {
          label: '工银e支付',
          value: 1
        },
        {
          label: '数币',
          value: 2
        }
      ],
      result: '',
      htmlText: '',
      paymentSetting: '',
      isClick: false,
      payData: {
        appId: '',
        timeStamp: '',
        nonceStr: '',
        package: '',
        signType: 'RSA',
        paySign: ''
      },
      isDialog: false,
      // 是否展示充值限制提示弹窗
      isShowRechargeLimit: false,
      // 充值弹窗提示信息
      rechargeLimitMsg: '',
      // 充值弹窗时间
      rechargeUpdateTime: '',
      // 充值类型
      rechargeType: '',
      user: JSON.parse(sessionStorage.getItem('personInfo')),
      disableButton: false
    };
  },
  computed: {
    navTitle() {
      if (this.activeTitle === 'Order') return '充值中心';
      if (this.activeTitle === 'History') return '充值历史';
      if (this.activeTitle === 'Record') return '消费记录';
      return '';
    }
    // rechargeAble() {
    //   // 获取今天是几号
    //   const today = dayjs().date();
    //   // 获取当月天数
    //   const days = dayjs().daysInMonth();
    //   // 匹配数字型字符串的正则表达式
    //   const regex = /-?\d+/g;
    //   let able = true;
    //   console.log(today, 'today');
    //   if (this.rechargeType === 0) {
    //     // 可充值时间
    //     if (this.rechargeLimitMsg) {
    //       if (this.rechargeLimitMsg.includes('月初')) {
    //         //  找到字符串中的数字型字符串
    //         const dayCount = this.rechargeLimitMsg.match(regex);
    //         console.log(dayCount, 'dayCount');
    //         if (today < dayCount[0] || today === Number(dayCount[0])) {
    //           able = true;
    //         } else {
    //           able = false;
    //         }
    //       } else if (this.rechargeLimitMsg.includes('月末')) {
    //         const dayCount = this.rechargeLimitMsg.match(regex);
    //         if (
    //           today > days - dayCount[0] + 1 ||
    //           today === days - dayCount[0] + 1
    //         ) {
    //           able = true;
    //         } else {
    //           able = false;
    //         }
    //       } else {
    //         if (this.rechargeLimitMsg.includes(today.toString())) {
    //           able = true;
    //         } else {
    //           able = false;
    //         }
    //       }
    //     }
    //   } else if (this.rechargeType === 1) {
    //     // 不可充值时间
    //     if (this.rechargeLimitMsg) {
    //       if (this.rechargeLimitMsg.includes('月初')) {
    //         //  找到字符串中的数字型字符串
    //         const dayCount = this.rechargeLimitMsg.match(regex);
    //         console.log(dayCount, 'dayCount');
    //         if (today < dayCount[0] || today === Number(dayCount[0])) {
    //           able = false;
    //         } else {
    //           able = true;
    //         }
    //       } else if (this.rechargeLimitMsg.includes('月末')) {
    //         const dayCount = this.rechargeLimitMsg.match(regex);
    //         if (
    //           today > days - dayCount[0] + 1 ||
    //           today === days - dayCount[0] + 1
    //         ) {
    //           able = false;
    //         } else {
    //           able = true;
    //         }
    //       } else {
    //         if (this.rechargeLimitMsg.includes(today.toString())) {
    //           able = false;
    //         } else {
    //           able = true;
    //         }
    //       }
    //       if (this.rechargeLimitMsg === '全月不可充值') {
    //         able = false;
    //       }
    //     }
    //   }
    //   return able;
    // }
  },
  async created() {
    console.log(
      Math.round(new Date().getTime() / 1000)
        .toString()
        .substr(0, 10)
    );
    this.personInfo = JSON.parse(sessionStorage.getItem('personInfo'));
    if (this.radio === 1) {
      this.phone = this.personInfo.phone;
    }
    this.paymentSetting = JSON.parse(
      this.personInfo.organization.paymentSetting
    );
    // 查询可用活动列表
    this.organizationId = this.personInfo.organizationId;
    const res = await getActivityList(this.organizationId);
    if (res.data.length > 0) {
      this.activityFlag = true;
      this.activityId = res.data[0].id;
      this.activityIntro = res.data[0].activityIntro;
      this.starttime = res.data[0].starttime;
      this.endtime = res.data[0].endtime;
      this.joinTimes = res.data[0].joinTimes;
      this.activityRule = JSON.parse(res.data[0].activityRule);
    }
    this.userId = this.personInfo.id;
    this.amountArr = this.activityRule.map((item) => {
      return Number(item.amount);
    });
    // 将amountArr中的amount从小到大排序
    this.amountArr.sort(function (a, b) {
      return a - b;
    });
    console.log(111, this.amountArr);
    for (const key in this.totalMoney) {
      this.totalMoney[key] = Number(
        Number(key) + Number(this.getCoupon(key)) / 100
      ).toFixed(2);
    }
    await this.getLimitFn();
  },
  methods: {
    toHome() {
      this.$router.back();
    },
    sortNumbersInString(str) {
      if (str === null) {
        return null;
      }

      // 匹配字符串中的数字并提取为数组
      const numbers = str.match(/\d+/g);

      // 如果没有匹配到数字，则直接返回原始字符串
      if (numbers === null) {
        return str;
      }

      // 将匹配到的数字转换为数字类型
      const numberArray = numbers.map(Number);

      // 对数字数组进行排序
      numberArray.sort((a, b) => a - b);

      // 重新构建字符串
      const sortedStr = str.replace(/\d+/g, () => numberArray.shift());

      return sortedStr;
    },
    async payFor() {
      if (this.limit.msg !== '' && this.limit.code === 500) {
        return;
      }
      this.isClick = true;
      const { h5PayUrl, h5PayInActivityUrl, money, moneyO } = this;
      const activityId = this.activityId;
      const userId = this.userId;
      if (this.activityFlag) {
        if (this.result === 1) {
          if (!money) {
            Toast('充值金额不能为空');
            this.isLink = false;
            return false;
          } else if (money <= 0) {
            Toast('充值金额必须大于0');
            this.isLink = false;
            return false;
          } else {
            this.isLink = true;
            const res = await getVerify(activityId, userId);
            if (res.code === 0) {
              if (res.data === null) {
                h5PayInActivityAPI(money, userId, activityId)
                  .then((res) => {
                    if (res.code === 0 && res.data) {
                      this.htmlText = res.data;
                      this.$nextTick(() => {
                        this.isClick = false;
                        document.forms[0].submit();
                      });
                    } else {
                      this.isClick = false;
                    }
                  })
                  .catch(() => {
                    this.isClick = false;
                  });
              } else {
                Dialog.confirm({
                  title: '是否继续充值？', // 当前用户已领取优惠次数 // 优惠总额度已领完
                  message: res.data
                })
                  .then(() => {
                    h5PayAPI(money, userId)
                      .then((res) => {
                        if (res.code === 0 && res.data) {
                          this.htmlText = res.data;
                          this.$nextTick(() => {
                            this.isClick = false;
                            document.forms[0].submit();
                          });
                        } else {
                          this.isClick = false;
                        }
                      })
                      .catch(() => {
                        this.isClick = false;
                      });
                  })
                  .catch(() => {
                    this.isClick = false;
                  });
              }
            }
          }
        } else if (this.result === 2) {
          // 数币充值
          if (!money) {
            Toast('充值金额不能为空');
            this.isLink = false;
            return false;
          } else if (money <= 0) {
            Toast('充值金额必须大于0');
            this.isLink = false;
            return false;
          } else {
            this.isLink = true;
            const res = await getVerify(activityId, userId);
            if (res.code === 0) {
              if (res.data === null) {
                h5DcepPayInActivityAPI(money, userId, activityId)
                  .then((res) => {
                    if (res.code === 0 && res.data) {
                      this.htmlText = res.data;
                      this.$nextTick(() => {
                        this.isClick = false;
                        document.forms[0].submit();
                      });
                    } else {
                      this.isClick = false;
                    }
                  })
                  .catch(() => {
                    this.isClick = false;
                  });
              } else {
                Dialog.confirm({
                  title: '是否继续充值？', // 当前用户已领取优惠次数 // 优惠总额度已领完
                  message: res.data
                })
                  .then(() => {
                    h5DcepPayAPI(money, userId)
                      .then((res) => {
                        if (res.code === 0 && res.data) {
                          this.htmlText = res.data;
                          this.$nextTick(() => {
                            this.isClick = false;
                            document.forms[0].submit();
                          });
                        } else {
                          this.isClick = false;
                        }
                      })
                      .catch(() => {
                        this.isClick = false;
                      });
                  })
                  .catch(() => {
                    this.isClick = false;
                  });
              }
            }
          }
        }
      } else {
        if (this.result === 1) {
          if (!moneyO) {
            Toast('充值金额不能为空');
            this.isLink = false;
            return false;
          } else if (moneyO <= 0) {
            Toast('充值金额必须大于0');
            this.isLink = false;
            return false;
          } else {
            this.isLink = true;
            h5PayAPI(moneyO, userId).then((res) => {
              if (res.code === 0 && res.data) {
                this.htmlText = res.data;
                this.$nextTick(() => {
                  this.isClick = false;
                  document.forms[0].submit();
                });
              } else {
                this.isClick = false;
              }
            });
          }
        } else if (this.result === 2) {
          if (!moneyO) {
            Toast('充值金额不能为空');
            this.isLink = false;
            return false;
          } else if (moneyO <= 0) {
            Toast('充值金额必须大于0');
            this.isLink = false;
            return false;
          } else {
            this.isLink = true;
            h5DcepPayAPI(moneyO, userId).then((res) => {
              if (res.code === 0 && res.data) {
                this.htmlText = res.data;
                this.$nextTick(() => {
                  this.isClick = false;
                  document.forms[0].submit();
                });
              } else {
                this.isClick = false;
              }
            });
          }
        } else if (this.result === 3) {
          if (!moneyO) {
            Toast('充值金额不能为空');
            this.isLink = false;
            return false;
          } else if (moneyO <= 0) {
            Toast('充值金额必须大于0');
            this.isLink = false;
            return false;
          } else {
            this.isLink = true;
            const user = JSON.parse(sessionStorage.getItem('personInfo'));
            // if (user.organization.code === '1030') {
            //   weixinPaymentAPI({
            //     openid: localStorage.getItem('openid'),
            //     amount: moneyO * 100
            //   }).then((res) => {
            //     if (res.code === 0) {
            //       this.payData = {
            //         appId: res.data.appId,
            //         timeStamp: res.data.timeStamp,
            //         nonceStr: res.data.nonceStr,
            //         package: res.data.packageVal,
            //         signType: res.data.signType,
            //         paySign: res.data.paySign
            //       };
            //       //  res.data;
            //       //                   this.payData = {
            //       //                     appId: "wx7018648a9b1035f3",
            //       //                     timeStamp: Math.round(new Date().getTime() / 1000)
            //       //                       .toString()
            //       //                       .substr(0, 10),
            //       //                     nonceStr: Math.round(new Date().getTime() / 1000)
            //       //                       .toString()
            //       //                       .substr(0, 10),
            //       //                     package: `prepay_id=${res.data.prepayId}`,
            //       //                     signType: "RSA",
            //       //                     paySign: ``,
            //       //                   };
            //       //                   // 私钥
            //       //                   let sikey = `
            //       // MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDDh0NOFINmUvZn
            //       // i0fIg6PXz4c0KIVgfv+2TSgWZtuCbtBguF8j6zni9Cz+LELTQi4JeMF9hQYf/GFo
            //       // 58lzPT+0qPEtk/seExhpscfnZ2irFrESKNKnkBzdvDRffkcMkDKJGXI1TMsGIxFW
            //       // 2ROwgUoVXlJeH2YptY9nla9f+J7vfYDTtV39mev0La2OZWbTquCoFYG6Vtkmhh46
            //       // ITz1rlK7395uEsISIJFJqT0Jsira3sRal0wfMwzFBW3se0Lw+YtxrAx3HMyJdVIl
            //       // pfZPhRDGHrcE8ST8bgtVDaPNmvq6Z+8wAR1OweOJP06RvSbF84n8MC1H+km/1uPO
            //       // poQzVOONAgMBAAECggEBAKje9/hFqz8CxCGln3fEigtoB2O644b16LBwbwD6EPfT
            //       // 8VC3rCP/kt06+mkDnSuMaupvSt23uBSECzVpiwlmitDjYHl251mQMHOIwhhPrYym
            //       // aoQ+kcF6rBOt6qXXEFH/cEYMniD+lBJW0zDpByJYZ72Q2x7Di57WWmaiVvdSaSJ5
            //       // BWUQ0xO/gHXI4zGO3kgqu6QtutOLicgCIPN3U90NnWsIz1++Wsoxs7BYohXmvjxq
            //       // DPXgjD2mbmG48xxrPvb3uT40MkC+T7zEcLwQaMdSan5wxShj+w8jxdhPiFhjCU+8
            //       // ngSVBZLvvuW8PWgKe7VGvIUIvrNIhdt3Tfbg5ji3VtkCgYEA7OJ6dPdVNbfZM8eA
            //       // mzCRfQN65/QCbnLtQjJ4jAs5z7MOXvN1bxB9eRaS1x9/ZkmPoREsyh318QQRhliQ
            //       // z514NqKIIjrxSdY9rhCYiPvo4CVlqSBlRXzjLBID0wp9S1/RwxIw4Falp50LTUti
            //       // +ZIW3rX//BR9CbsRrAF6O/VIo78CgYEA0050CF1t6ehO5i/HAkuKxJJaSnT9a0qk
            //       // JzNpTWvfhPVOUQuZUtvzaT0wozJME6zjTtCKrGs5INGN8pt1UALZ80L0MDCI5TOz
            //       // Uuw00SDLpOMjnQse68uRLncEa9vShGt78O9CjlNdlJJyiDvw9Yckmcfuz8nr33BK
            //       // VfoP/dgK27MCgYAP77ifT9hqscCqDBzp092l1GsbWb4kIhU2I+EaQtMT1w77clOP
            //       // MkPOtqcQU+0lO4OvKVGjE1rtaQGo0SKE5SomOs7/xolfF0u0/ybT6GalVb6+HxDR
            //       // GPLLjza0pvsA7CRXhEzHeuzQFu+gtpalBaAGQaZEFjjHQ8+Pi9XmXL0TTwKBgGB7
            //       // XffD0UAQVpY8En41x/LhcGC28k9fEXzkuJwlOTYYW94dkbCHxaizy9V4TcxeEJwY
            //       // FHmNq9QUWt//o50Y8DNmyuCGCLu+0451U9h5RQLQw54dSc0Ycf3pUhd9k63q9fiZ
            //       // Bqa94ejIxYr285roTgn5hE1UQWMQfqCrQUoCr1KJAoGAFHSpojGi/L5g5N8LiDgR
            //       // 99EA+j8vEHQZLR6SOznGGBHPoS6YBb7bSEak2cU/6CdBvO1nUMQdp0QBtGOsq8aq
            //       // RElUcU7xpwZJuutOdXFR3zLtN4F+YQuKO6oVuD1RLy9uRYrvAV5MrCNULCPgAVhM
            //       // B41w628OvENPPFJrCdYxM1s=
            //       // `;
            //       //                   const paySign = `wx7018648a9b1035f3\n${Math.round(
            //       //                     new Date().getTime() / 1000
            //       //                   )
            //       //                     .toString()
            //       //                     .substr(0, 10)}\n${Math.round(new Date().getTime() / 1000)
            //       //                     .toString()
            //       //                     .substr(0, 10)}\nprepay_id=${res.data.prepayId}\n`;
            //       // this.payData.paySign = this.rsaSign(paySign, sikey);
            //       if (typeof WeixinJSBridge == 'undefined') {
            //         if (document.addEventListener) {
            //           this.isClick = false;
            //           document.addEventListener(
            //             'WeixinJSBridgeReady',
            //             this.onBridgeReady,
            //             false
            //           );
            //         } else if (document.attachEvent) {
            //           this.isClick = false;
            //           document.attachEvent(
            //             'WeixinJSBridgeReady',
            //             this.onBridgeReady
            //           );
            //           document.attachEvent(
            //             'onWeixinJSBridgeReady',
            //             this.onBridgeReady
            //           );
            //         }
            //       } else {
            //         this.isClick = false;
            //         this.onBridgeReady();
            //       }
            //     }
            //   });
            // } else {

            weixinPaymentAPI1({
              openid: localStorage.getItem('openid'),
              amount: moneyO * 100
            }).then((res) => {
              if (res.code === 0) {
                this.payData = {
                  appId: res.data.appId,
                  timeStamp: res.data.timeStamp,
                  nonceStr: res.data.nonceStr,
                  package: res.data.packageVal,
                  signType: res.data.signType,
                  paySign: res.data.paySign
                };
                //  res.data;
                //                   this.payData = {
                //                     appId: "wx7018648a9b1035f3",
                //                     timeStamp: Math.round(new Date().getTime() / 1000)
                //                       .toString()
                //                       .substr(0, 10),
                //                     nonceStr: Math.round(new Date().getTime() / 1000)
                //                       .toString()
                //                       .substr(0, 10),
                //                     package: `prepay_id=${res.data.prepayId}`,
                //                     signType: "RSA",
                //                     paySign: ``,
                //                   };
                //                   // 私钥
                //                   let sikey = `
                // MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDDh0NOFINmUvZn
                // i0fIg6PXz4c0KIVgfv+2TSgWZtuCbtBguF8j6zni9Cz+LELTQi4JeMF9hQYf/GFo
                // 58lzPT+0qPEtk/seExhpscfnZ2irFrESKNKnkBzdvDRffkcMkDKJGXI1TMsGIxFW
                // 2ROwgUoVXlJeH2YptY9nla9f+J7vfYDTtV39mev0La2OZWbTquCoFYG6Vtkmhh46
                // ITz1rlK7395uEsISIJFJqT0Jsira3sRal0wfMwzFBW3se0Lw+YtxrAx3HMyJdVIl
                // pfZPhRDGHrcE8ST8bgtVDaPNmvq6Z+8wAR1OweOJP06RvSbF84n8MC1H+km/1uPO
                // poQzVOONAgMBAAECggEBAKje9/hFqz8CxCGln3fEigtoB2O644b16LBwbwD6EPfT
                // 8VC3rCP/kt06+mkDnSuMaupvSt23uBSECzVpiwlmitDjYHl251mQMHOIwhhPrYym
                // aoQ+kcF6rBOt6qXXEFH/cEYMniD+lBJW0zDpByJYZ72Q2x7Di57WWmaiVvdSaSJ5
                // BWUQ0xO/gHXI4zGO3kgqu6QtutOLicgCIPN3U90NnWsIz1++Wsoxs7BYohXmvjxq
                // DPXgjD2mbmG48xxrPvb3uT40MkC+T7zEcLwQaMdSan5wxShj+w8jxdhPiFhjCU+8
                // ngSVBZLvvuW8PWgKe7VGvIUIvrNIhdt3Tfbg5ji3VtkCgYEA7OJ6dPdVNbfZM8eA
                // mzCRfQN65/QCbnLtQjJ4jAs5z7MOXvN1bxB9eRaS1x9/ZkmPoREsyh318QQRhliQ
                // z514NqKIIjrxSdY9rhCYiPvo4CVlqSBlRXzjLBID0wp9S1/RwxIw4Falp50LTUti
                // +ZIW3rX//BR9CbsRrAF6O/VIo78CgYEA0050CF1t6ehO5i/HAkuKxJJaSnT9a0qk
                // JzNpTWvfhPVOUQuZUtvzaT0wozJME6zjTtCKrGs5INGN8pt1UALZ80L0MDCI5TOz
                // Uuw00SDLpOMjnQse68uRLncEa9vShGt78O9CjlNdlJJyiDvw9Yckmcfuz8nr33BK
                // VfoP/dgK27MCgYAP77ifT9hqscCqDBzp092l1GsbWb4kIhU2I+EaQtMT1w77clOP
                // MkPOtqcQU+0lO4OvKVGjE1rtaQGo0SKE5SomOs7/xolfF0u0/ybT6GalVb6+HxDR
                // GPLLjza0pvsA7CRXhEzHeuzQFu+gtpalBaAGQaZEFjjHQ8+Pi9XmXL0TTwKBgGB7
                // XffD0UAQVpY8En41x/LhcGC28k9fEXzkuJwlOTYYW94dkbCHxaizy9V4TcxeEJwY
                // FHmNq9QUWt//o50Y8DNmyuCGCLu+0451U9h5RQLQw54dSc0Ycf3pUhd9k63q9fiZ
                // Bqa94ejIxYr285roTgn5hE1UQWMQfqCrQUoCr1KJAoGAFHSpojGi/L5g5N8LiDgR
                // 99EA+j8vEHQZLR6SOznGGBHPoS6YBb7bSEak2cU/6CdBvO1nUMQdp0QBtGOsq8aq
                // RElUcU7xpwZJuutOdXFR3zLtN4F+YQuKO6oVuD1RLy9uRYrvAV5MrCNULCPgAVhM
                // B41w628OvENPPFJrCdYxM1s=
                // `;
                //                   const paySign = `wx7018648a9b1035f3\n${Math.round(
                //                     new Date().getTime() / 1000
                //                   )
                //                     .toString()
                //                     .substr(0, 10)}\n${Math.round(new Date().getTime() / 1000)
                //                     .toString()
                //                     .substr(0, 10)}\nprepay_id=${res.data.prepayId}\n`;
                // this.payData.paySign = this.rsaSign(paySign, sikey);
                if (typeof WeixinJSBridge === 'undefined') {
                  if (document.addEventListener) {
                    this.isClick = false;
                    document.addEventListener(
                      'WeixinJSBridgeReady',
                      this.onBridgeReady,
                      false
                    );
                  } else if (document.attachEvent) {
                    this.isClick = false;
                    document.attachEvent(
                      'WeixinJSBridgeReady',
                      this.onBridgeReady
                    );
                    document.attachEvent(
                      'onWeixinJSBridgeReady',
                      this.onBridgeReady
                    );
                  }
                } else {
                  this.isClick = false;
                  this.onBridgeReady();
                }
              }
            });
          }
        }
        // }
      }
      this.isClick = false;
    },
    toTitle(value) {
      const active = value;
      switch (active) {
        case 'Order':
          this.activeTitle = 'Order';
          this.$router.push({ name: 'RechargeCenter' });
          break;
        case 'History':
          this.activeTitle = 'History';
          this.$router.push({ name: 'RechargeHistory' });
          break;
        case 'Record':
          this.activeTitle = 'Record';
          this.$router.push({ name: 'Consumption' });
          break;
      }
    },
    // 原输入框
    toRecharge(value) {
      switch (value) {
        case 1:
          this.moneyO = 100;
          break;
        case 2:
          this.moneyO = 200;
          break;
        case 3:
          this.moneyO = 500;
          break;
      }
    },
    // async getRechargeAct() {
    //   await this.toRechargeAct()
    // },

    // 有活动的输入框
    toRechargeAct(value) {
      switch (value) {
        case 1:
          this.money = 50;
          this.coupon = this.getCoupon(this.money * 100);
          break;
        case 2:
          this.money = 100;
          this.coupon = this.getCoupon(this.money * 100);
          break;
        case 3:
          this.money = 200;
          this.coupon = this.getCoupon(this.money * 100);
          break;
      }
    },
    getCoupon(money) {
      // debugger
      let amount = money * 100;
      const moneyNum = Number(money * 100);
      let coupon = 0;

      // for (let i = 0; i < this.amountArr.length; i++) {
      //   if (moneyNum === Number(this.amountArr[i])) {
      //     amount = this.amountArr[i]
      //     break
      //   } else if (i > 0 && moneyNum < Number(this.amountArr[i])) {
      //     amount = this.amountArr[i - 1]
      //     break
      //   } else if (i === this.amountArr.length - 1) {
      //     amount = this.amountArr[i]
      //   } else if (moneyNum < Number(this.amountArr[0])) {
      //     amount = money * 100
      //   }
      // }

      // for (let i = 0; i < this.amountArr.length; i++) {
      //   if (moneyNum === Number(this.amountArr[i])) {
      //     amount = this.amountArr[i]
      //     break
      //   } else if (i === this.amountArr.length - 1) {
      //     amount = this.amountArr[i]
      //     break
      //   } else if (i > 0 && moneyNum < Number(this.amountArr[i])) {
      //     amount = this.amountArr[i - 1]
      //   }
      // }

      for (let i = 0; i < this.amountArr.length; i++) {
        if (moneyNum < Number(this.amountArr[0])) {
          amount = this.money * 100;
          break;
        } else if (moneyNum === Number(this.amountArr[i])) {
          amount = this.amountArr[i];
          break;
        } else if (i > 0 && moneyNum < Number(this.amountArr[i])) {
          amount = this.amountArr[i - 1];
          break;
        } else if (i === this.amountArr.length - 1) {
          amount = this.amountArr[i];
        } else if (moneyNum < Number(this.amountArr[0])) {
          amount = money * 100;
        }
      }

      this.activityRule.forEach((item) => {
        if (Number(item.amount) === Number(amount)) {
          coupon = Number(item.coupon);
        }
      });
      return coupon + '';
    },
    load() {
      this.consumeList.push(this.consumeList);
    },
    everyDay(value) {
      value = moment(value).format('YYYYMMDD');
      const month = moment(value).month() + 1;
      const date = moment(value).date();
      const days = +moment(value).day();
      let day;
      switch (days) {
        case 0:
          day = '日';
          break;
        case 1:
          day = '一';
          break;
        case 2:
          day = '二';
          break;
        case 3:
          day = '三';
          break;
        case 4:
          day = '四';
          break;
        case 5:
          day = '五';
          break;
        case 6:
          day = '六';
          break;
      }
      return `${month}月${date}日 周${day}`;
    },

    changeObject(val) {
      if (val === 1) {
        this.phone = this.personInfo.phone;
      } else {
        this.phone = '';
      }
    },

    inputMoney() {
      this.coupon = this.getCoupon(this.money);
    },
    async getLimitFn() {
      this.user = JSON.parse(sessionStorage.getItem('personInfo'));
      const res = await getLimitAPI(this.user.organizationId);
      if (res.data.rechargeType === 0 && res.data.msg) {
        this.rechargeType = res.data.rechargeType;
        this.rechargeLimitMsg = res.data.msg;
        this.rechargeUpdateTime = dayjs(res.data.updateDate).format(
          'YYYY-MM-DD'
        );
      } else if (res.data.rechargeType === 1 && res.data.msg) {
        this.rechargeType = res.data.rechargeType;
        this.rechargeLimitMsg = res.data.msg;
        this.rechargeUpdateTime = dayjs(res.data.updateDate).format(
          'YYYY-MM-DD'
        );
      }
      if (res.data.code === 500) {
        this.isShowRechargeLimit = true;
        this.disableButton = true;
        return;
      }
    },
    closeFn() {
      this.limit.isShow = false;
    },
    getSystemSettingFn() {
      getSystemSettingAPI().then((res) => {
        if (res.code === 0) {
          if (res.data) {
            if (
              res.data.status === 1 &&
              !res.data.whiteList.includes(this.user.phone)
            ) {
              this.isDialog = true;
            }
          }
        }
      });
    },
    isOnSubmitFn() {
      if (this.disableButton) {
        return;
      }
      this.getSystemSettingFn();
      if (this.isDialog) {
        return;
      }
      if (this.limit.msg !== '' && this.limit.code === 500) {
        return;
      }
      const { money, moneyO } = this;
      console.log(money, moneyO);
      if (this.activityFlag) {
        // 数币充值
        if (!money) {
          Toast('充值金额不能为空');
          this.isLink = false;
          return;
        } else if (money <= 0) {
          Toast('充值金额必须大于0');
          this.isLink = false;
          return;
        }
      } else {
        if (!moneyO) {
          Toast('充值金额不能为空');
          this.isLink = false;
          return;
        } else if (moneyO <= 0) {
          Toast('充值金额必须大于0');
          this.isLink = false;
          return;
        }
      }
      this.show = true;
    },
    onBridgeReady() {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          ...this.payData
        },
        (res) => {
          console.log(res, '返回的支付信息.');
          this.isClick = false;
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            Toast('支付成功!');
            this.show = false;
            this.moneyO = '';
            // 使用以上方式判断前端返回,微信团队郑重提示：
            // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
            Toast('已取消!');
          } else if (res.err_msg == 'get_brand_wcpay_request:fail') {
            Toast('支付失败!');
          }
        }
      );
    },
    rsaSign(content, privateKey) {
      const { KJUR, hextob64 } = require('../../utils/jsrsasign');
      // 创建 Signature 对象
      privateKey = `-----BEGIN PRIVATE KEY-----\n${privateKey}\n-----END PRIVATE KEY-----`;
      const signature = new KJUR.crypto.Signature({
        alg: 'SHA256withRSA',
        prvkeypem: privateKey
      });
      signature.updateString(content);
      const signData = signature.sign();
      // 将内容转成base64
      return hextob64(signData);
      // return signData;
    },
    randomString() {
      const len = 32;
      const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678';
      const maxPos = chars.length;
      let character = '';
      for (let i = 0; i < len; i++) {
        character += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return character;
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
  & > .nav {
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    //position: relative;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }

  & > .acc {
    padding-top: 2.5rem;

    & > .lable-charge {
      display: flex;
      justify-content: center;
      margin: 8px auto;

      & > .header_tabbar_btn.header_tabbar_btn_active {
        font-size: 1rem;
        color: #ffffff;
        background-color: #81929e;
        width: 8.8rem;
        border: 2px solid #81929e;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > .header_tabbar_btn {
        background-color: #fff;
        font-size: 15px;
        color: #333333;
        width: 8.8rem;
        height: 1.8rem;
        border: 2px solid #81929e;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > .header_tabbar_btn.left {
        border-radius: 6px 0 0 6px;
        border-right: none;
      }

      & > .header_tabbar_btn.right {
        border-radius: 0 6px 6px 0;
        border-left: none;
      }
    }

    .acc-auto {
      display: flex;
      justify-content: space-between;
      margin: 1.25rem 0;
      background-color: #fff;
      padding: 0.7rem 0.3rem;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;

      & > .recharge-num {
        width: 7rem;
        border: 1px solid #ececec;
        height: 2.5rem;
        border-radius: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        color: #333;
      }

      & > .recharge_num_active {
        border-color: #c20000;
        color: #c20000;
      }
    }

    .acc-auto1 {
      display: flex;
      justify-content: space-between;
      margin: 1.25rem 0;
      background-color: #fff;
      padding: 0.7rem 0.3rem;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;

      & > .recharge-num {
        width: 7rem;
        border: 1px solid #ececec;
        height: 2.7rem;
        border-radius: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        color: #333;
      }

      & > .recharge_num_active {
        border-color: #c20000;
        color: #c20000;
      }
    }

    .account {
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      background-color: #fff;

      & > .cell_li_left {
        display: inline-block;
        margin-left: 10px;
        font-size: 0.9rem;
        width: 60px;
        color: #333;
      }
    }

    .normal_btn {
      -webkit-box-flex: 1;
      font-size: 0.95rem;
      color: white;
      background: #ff7b7b;
      border: 1px #ff7b7b solid;
      -webkit-appearance: none;
      padding: 0 2px;
      margin: 2rem 0.6rem;
      min-height: 40px;
      border-radius: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active {
      background: #ccc;
      border: 1px #ccc solid;
    }

    /* .normal_btn:active {
      border: 1px #e66565 solid;
      background: #e66565;
    } */

    .history {
      display: flex;
      align-items: center;
      background-color: #ffffff;
      justify-content: space-between;
      padding-left: 1rem;
      border-bottom: 1px solid #e5e5e5;
      border-top: 1px solid #e5e5e5;
      margin-top: 1.25rem;

      & > .query {
        width: 3rem;
        background-color: #db443c;
        height: 42px;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.1rem;
      }
    }

    .only-text {
      font-size: 0.8rem;
      margin: 0.4rem 1.25rem;
    }

    .detail {
      border-bottom: 1px solid #e5e5e5;
      border-top: 1px solid #e5e5e5;
      padding: 0 1.25rem;
      background-color: #fff;

      & > .order-title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;

        & > .order-num {
          color: #888888;
          font-size: 0.95rem;
          padding: 0.5rem 0;
        }
      }

      & > .order-content {
        padding: 0.5rem 0;
        //border-bottom: 1px solid #e5e5e5;

        & > .order-line {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & > .order-text {
            font-size: 0.9rem;
          }
        }

        & > .order-line.two {
          margin-top: 0.4rem;
        }
      }

      & > .order-up {
        display: flex;
        justify-content: center;
        padding: 0.1rem 0;
        transform: rotate(180deg);
        transition: height 2s;
      }

      .to_stretch {
        height: 10rem;
      }
    }

    .tips {
      margin: 3rem 1.25rem;

      & > .tip-title {
        color: #db443c;
        font-size: 1rem;
      }

      & > .tip-content {
        color: #888888;
        font-size: 1rem;
        margin-top: 0.3rem;

        & > .p-number {
          color: #309bf0;
        }
      }
    }

    .item-margin {
      margin-top: 1.25rem;
      overflow: scroll;

      .consume {
        background-color: #fff;
        padding: 1.2rem 1.5rem 0;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        margin-top: 0.3rem;

        & > .con-date {
          font-weight: bold;
          color: #333333;
        }

        & > .con-details {
          padding: 0.8rem 0;
          border-bottom: 1px solid #f5f5f5;

          & > .con-item.first {
            margin-bottom: 0.1rem;
          }

          & > .con-item {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            & > .con-title {
              color: #575757;
              font-size: 0.95rem;
            }

            & > .moneyO {
              color: #66a965;
            }

            & > .con-detail {
              color: #888888;
              font-size: 0.83rem;
              width: 9rem;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            & > .con-surplus {
              color: #888888;
              font-size: 0.95rem;
            }
          }
        }
      }
    }
  }

  .account .input1-acc {
    // border: none;
    border: #e5e5e5 1px solid;
    font-size: 0.9rem;
    height: 40px;
    outline: medium;
    color: #798692;
    caret-color: #4b95cb;
    width: 240px;
    // margin-bottom: 10px;
  }

  input::-webkit-input-placeholder {
    color: #c0cbcb;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.3);
    .tips {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .tips_center {
        position: relative;
        padding: 0 0.5rem;
        width: 80%;
        background: white;
        border-radius: 5px;
        color: rgba(51, 51, 51);
        word-break: break-all;
        h3 {
          margin: 0;
          font-weight: bolder;
          text-align: center;
        }
        span {
          font-weight: bolder;
          font-size: 0.8rem;
        }
        p {
          margin: 0;
          font-weight: bolder;
          font-size: 0.9rem;
          line-height: 1.5rem;
          text-indent: 1.8rem;
        }
        .tips_right {
          width: 100%;
          text-align: right;
        }
        .cancel {
          position: absolute;
          bottom: -2.5rem;
          width: 100%;
          width: 2rem;
          height: 2rem;
          border: 2px solid white;
          color: white;
          border-radius: 2rem;
          line-height: 2rem;
          text-align: center;
          margin-left: 40%;
        }
      }
    }
  }
}
.limit-dialog {
  width: 343px;
  border-radius: 12px;
  .title {
    font-size: 18px;
    font-family: 'PingFangSC-Regular';
    font-weight: 500;
    color: #333333;
    box-sizing: border-box;
    padding: 28px 0 20px 0;
  }
  .body {
    padding: 0 16px;
    line-height: 21px;
    font-size: 14px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #666666;
    margin-bottom: 12px;
    text-align: justify;
    p {
      margin: 0;
      word-break: break-all;
    }
  }
}
::v-deep .van-toast--html .van-toast__text,
.van-toast--text .van-toast__text {
  color: #fff;
}
.tel {
  background-color: #fff;
  margin: 10px 0;

  & > .text {
    display: inline-block;
    font-size: 0.9rem;
    width: 70px;
    color: #333;
  }
}
.phone {
  // border: none;
  border: #e5e5e5 1px solid;
  font-size: 0.9rem;
  height: 40px;
  outline: medium;
  color: #798692;
  caret-color: #4b95cb;
  width: 240px;
}

.details {
  box-sizing: border-box;
  padding: 10px;
  margin-top: 15px;
  background: #fff;
  .detail-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .title {
    color: #888888;
    font-size: 14px;
    font-weight: bold;
  }
  .amount {
    color: #d9001b;
    font-size: 14px;
    font-weight: bold;
  }
  div {
    margin: 5px 0;
  }
  ul {
    li {
      text-indent: 2rem;
      color: #888888;
      font-size: 12px;
      display: flex;
      box-sizing: border-box;
      padding-right: 20px;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.description {
  box-sizing: border-box;
  padding: 10px;
  margin-top: 15px;
  background: #fff;
  .title {
    color: #000;
    font-weight: bold;
    text-align: center;
  }
  .subhead {
    font-size: 12px;
    color: #aaaaaa;
  }

  .text {
    font-size: 12px;
    color: #d9001b;
  }
}
.limit {
  pointer-events: none;
  background: #cccc;
}

.payment_popup {
  width: 100%;
  background: rgba(248, 248, 248);
  .payment_in {
    padding: 0.5rem;
    .payment_top {
      text-align: right;
      height: 1.5rem;
      img {
        width: 1.5rem;
      }
    }
    .payment_money {
      display: flex;
      justify-content: center;
      color: #000;
      h1 {
        margin: 0;
        font-weight: 900;
      }
      p {
        font-weight: 900;
        margin-left: 0.5rem;
      }
    }
    .payment_order {
      height: 2rem;
      padding: 0 1rem;
      background: rgba(255, 255, 255);
      line-height: 2rem;
      font-size: 0.9rem;
      color: #000;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      p {
        height: 2rem;
        margin: 0;
      }
    }
    .payment_select {
      padding: 0 0.5rem;
      background: rgba(255, 255, 255);
      font-size: 1.5rem;
    }
    .payment_btn {
      position: fixed;
      bottom: 0.5rem;
      width: 100%;
      text-align: center;
      .van-button {
        width: 80%;
        background: rgba(238, 130, 128);
        color: white;
        font-size: 1rem;
      }
    }
  }
}

.dialog_div {
  height: 100px;
  width: 100%;
  text-align: center;
  line-height: 100px;
  font-size: 14px;
  color: rgba(63, 63, 63);
}
::v-deep .van-dialog__header {
  padding: 0 !important;
  color: black;
  font-weight: 900;
}
::v-deep .van-cell::after {
  border: none !important;
}
::v-deep .van-cell__title,
.van-cell__value {
  font-size: 1rem !important;
}
</style>
