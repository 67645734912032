import { isNull } from 'lodash';
import { queryEmployee } from '@/api/api';

const state = {
  userId: null,
  name: null,
  tel: null,
  photo: null,
  openOrder: null,
  allowance: null,
  role: null,
  manager: null,
  discountConfig: null,
  addressDefault: {}
};

const mutations = {
  SET_USERID: (state, userId) => {
    state.userId = userId;
  },
  SET_ACCOUNTID: (state, userId) => {
    state.userId = userId;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_ROLE: (state, role) => {
    state.role = role;
  },
  SET_TEL: (state, tel) => {
    state.tel = tel;
  },
  SET_PHOTO: (state, photo) => {
    state.photo = photo;
  },
  SET_OpenOrder: (state, openOrder) => {
    state.openOrder = openOrder;
  },
  SET_Allowance: (state, allowance) => {
    state.allowance = allowance;
  },
  SET_DISCOUNT: (state, discountRule) => {
    state.discountRule = discountRule;
  },
  SET_MANAGER: (state, manager) => {
    state.manager = manager;
  },
  SET_DiscountConfig: (state, discountConfig) => {
    state.discountConfig = discountConfig;
  },
  SET_ADDRESS_DEFAULT: (state, addressDefault) => {
    state.addressDefault = addressDefault;
  }
};

const actions = {
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      queryEmployee()
        .then((response) => {
          const { code, data } = response;
          console.log('user', data);
          sessionStorage.setItem('personInfo', JSON.stringify(data));
          if (code === 0) {
            const {
              id,
              role,
              name,
              tel,
              photo,
              allowance,
              discountRule,
              organization: { openOrder },
              classManager,
              departmentManager,
              discountConfig
            } = data;
            commit('SET_USERID', id);
            commit('SET_ROLE', role);
            commit('SET_ACCOUNTID', id);
            commit('SET_NAME', name);
            commit('SET_TEL', tel);
            commit('SET_PHOTO', photo);
            commit('SET_OpenOrder', openOrder);
            commit('SET_Allowance', allowance);
            commit('SET_DISCOUNT', discountRule);
            if (role === 1) {
              if (!isNull(classManager)) commit('SET_MANAGER', classManager.id);
            } else {
              if (!isNull(departmentManager)) {
                commit('SET_MANAGER', departmentManager.id);
              }
            }
            commit('SET_DiscountConfig', discountConfig);
            resolve(data);
          } else {
            reject('获取用户信息失败!');
          }
          console.log(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
