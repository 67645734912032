<template>
  <div class="container">
    <div class="content">
      <div class="card phone">
        <van-field
          v-model="phone"
          clearable
          label-width="4.8rem"
          label="手机号"
          placeholder="请输入手机号"
          type="number"
        />
      </div>
      <div class="card phone">
        <van-cell-group>
          <van-field
            v-model="verifyCode"
            center
            clearable
            label="短信验证码"
            label-width="4.8rem"
            placeholder="请输入短信验证码"
            :border="false"
            use-button-slot
            maxlength="6"
          >
            <van-button
              slot="button"
              size="small"
              type="primary"
              style="
                background-color: #ff7b7b;
                border: none;
                height: 22px;
                width: 60px;
                top: -2px;
              "
              :disabled="!showTime"
              @click="getCode"
            >
              {{ !showTime ? sendTime + '秒' : '获取' }}
            </van-button>
          </van-field>
        </van-cell-group>
      </div>
      <div class="btn">
        <van-row>
          <van-col span="24">
            <van-button type="danger" class="vbutton" block @click="confirm">
              {{ action === '' ? '绑定' : '提交' }}
            </van-button>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  login,
  identifyingCode,
  getPhoneNumber,
  weixinLoginAPI,
  getOrgByMobile
} from '@/api/api';
import { Toast } from 'vant';

export default {
  name: 'Login',
  data() {
    return {
      sendTime: '',
      verifyCode: '',
      phone: '',
      showTime: true,
      timer: null,
      action: ''
    };
  },
  created() {
    this.$route.query.action
      ? (this.action = this.$route.query.action)
      : (this.action = '');
    console.log(this.action, 'this.action');
  },
  methods: {
    async getCode() {
      const { phone } = this;
      if (!phone) {
        return Toast('请输入手机号');
      }
      const res = await getPhoneNumber(phone);
      console.log(res, 111);

      if (!res.data || res.data.length === 0) {
        return Toast('该用户不存在,请查看手机号是否正确');
      } else {
        const params = {
          phone,
          appKey: 'ZHONGHEZHIHUI123!@#'
        };

        const response = await identifyingCode(params);
        if (response.code === 0) {
          Toast('获取验证码成功');

          const defaulTime = 60;
          if (!this.timer) {
            this.sendTime = defaulTime;
            this.showTime = false;
            this.timer = setInterval(() => {
              if (this.sendTime > 0 && this.sendTime <= defaulTime) {
                this.sendTime--;
              } else {
                this.showTime = true;
                clearInterval(this.timer); // 清除定时器
                this.timer = null;
              }
            }, 1000);
          }
        }
      }
    },
    async confirm() {
      const { $router, phone, verifyCode } = this;
      if (!phone) {
        Toast('请输入手机号');
        return;
      } else if (!verifyCode) {
        Toast('请输入短信验证码');
      }
      const { custID } = this.$route.query;
      // console.log('custID', custID)
      // console.log('custID', this.$route.query)
      // sessionStorage.setItem('custID', JSON.stringify(this.$route.query.custID))
      // sessionStorage.setItem('custID', custID)

      const datas = {
        custID,
        phone,
        verifyCode
      };
      if (this.action === 'weixin') {
        console.log('微信登录');
        weixinLoginAPI({
          phone: datas.phone,
          code: datas.verifyCode
        }).then((res) => {
          if (res.code === 0) {
            localStorage.setItem('phone', datas.phone);
            const homeUrl =
              window.location.href.split('order-login?action=')[0] +
              `?phone=${datas.phone}&zhonghezhihui=1`;
            window.location.href = homeUrl;
          }
        });
      } else if (this.action === 'zhonghezhihui') {
        console.log('zhonghezhihui');
        weixinLoginAPI({
          phone: datas.phone,
          code: datas.verifyCode
        }).then((res) => {
          if (res.code === 0) {
            this.getOrgByMobileFn();
          }
        });
      } else {
        const res = await login(datas);
        const { code, message } = res;
        if (code === 0) {
          Toast.success({
            message: '绑定成功',
            duration: 1000,
            onClose: () => {
              $router.push({ name: 'Home', query: { phone, custID } });
            }
          });
        } else {
          Toast.success({
            message,
            duration: 1000
          });
        }
      }
    },
    getOrgByMobileFn() {
      const { phone } = this;
      getOrgByMobile({ phone }).then((res) => {
        if (res.code === 0) {
          if (res.data.length === 0) {
            Toast({
              message: '登录的手机号无效!'
            });
          } else {
            this.orgs = res.data.map((item) => {
              return { ...item, name: item.zhName };
            });
            if (this.orgs.length === 0) {
              Toast({
                message: '登录的手机号无效!'
              });
            } else if (this.orgs.length === 1) {
              this.organizationId = res.data[0].id;
              this.getUserListFn();
            } else {
              this.orgShow = true;
            }
          }
        } else {
          Toast({
            message: '登录的手机号无效!'
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  & > .nav {
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 3rem;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 3rem;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }
    & > .nav_left_btn:active {
      opacity: 0.5;
    }
    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }

  & > .content {
    padding-top: 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .card.phone {
      margin-top: 2rem;
    }

    .num {
      width: 5rem;
      text-align: right;
    }
    & > .btn {
      margin: 6rem 0.5rem 0;
      width: 85%;

      & > .vbutton {
        width: 100%;
        height: 70px;
        margin-bottom: 30px;
        font-size: 18px;
      }
    }
  }
}
::v-deep .van-hairline--top-bottom::after {
  border: none;
}
::v-deep .van-button--block {
  background-color: #ff7b7b;
  border: 1px solid #ff7b7b;
  border-radius: 2rem;
}
::v-deep .van-button__content {
  font-size: 0.95rem;
}
</style>

<style scoped>
.card {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  background-color: #ffffff;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 0 0.3rem #dcdcdc;
  border: 1px solid #f5f5f5;
  width: 85%;
}
</style>
