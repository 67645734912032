<template>
  <div class="container">
    <headTitle title="营养分析"></headTitle>
    <div style="box-sizing: border-box; padding-bottom: 20px">
      <div class="nutrition_top">
        <div class="title">已选食物</div>
        <div class="content">
          <div
            v-for="(item, index) in order.products"
            :key="index"
            class="content_in"
          >
            <div class="left">
              <img
                style="width: 100%; height: 100%"
                :src="item.logo ? `${apiURL}/image?name=${item.logo}` : dish"
                alt
              />
            </div>
            <div class="right">
              <div class="top">{{ item.name }}</div>
              <div class="bottom">
                <van-stepper
                  v-model="item.weight"
                  :min="0"
                  :max="500"
                  :default-value="100"
                  integer
                  theme="round"
                  button-size="24"
                  @change="stepperChangeFn(index)"
                />
                <div class="unit">(单位/g)</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="nutrition_bottom">
        <div class="title">整体评价</div>
        <div class="top">
          <div class="top_img">
            <!-- 状态良好 -->
            <img
              style="width: 253px; height: 70px"
              src="../../assets/line_backgrund.png"
            />
            <img
              v-if="mode === 0"
              style="
                width: 48.42px;
                height: 46.71px;
                position: absolute;
                left: 0;
                bottom: 6px;
              "
              src="../../assets/first.png"
            />
            <img
              v-if="mode === 1"
              mode="scaleToFill"
              style="
                width: 100.38px;
                height: 67.35px;
                position: absolute;
                left: 0;
                bottom: 6px;
              "
              src="../../assets/second.png"
            />
            <img
              v-if="mode === 2"
              mode="scaleToFill"
              style="
                width: 152.09px;
                height: 70.5px;
                position: absolute;
                left: 0;
                bottom: 6px;
              "
              src="../../assets/thirdly.png"
            />
            <img
              v-if="mode === 3"
              mode="scaleToFill"
              style="
                width: 204.27px;
                height: 70.5px;
                position: absolute;
                left: 0;
                bottom: 6px;
              "
              src="../../assets/fourthly.png"
            />
            <img
              v-if="mode === 4"
              mode="scaleToFill"
              style="
                width: 253.5px;
                height: 70px;
                position: absolute;
                left: 0;
                bottom: 6px;
              "
              src="../../assets/fifth.png"
            />

            <div
              v-if="mode === 0"
              style="
                position: absolute;
                left: 0;
                bottom: 0;
                width: 253.5px;
                height: 60px;
                line-height: 60px;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                color: rgba(255, 168, 0);
              "
            >
              差
            </div>
            <div
              v-if="mode === 1"
              style="
                position: absolute;
                left: 0;
                bottom: 0;
                width: 253.5px;
                height: 60px;
                line-height: 60px;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                color: rgba(255, 168, 0);
              "
            >
              一般
            </div>
            <div
              v-if="mode === 2"
              style="
                position: absolute;
                left: 0;
                bottom: 0;
                width: 253px;
                height: 60px;
                line-height: 60px;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                color: rgba(10, 211, 170);
              "
            >
              良好
            </div>
            <div
              v-if="mode === 3"
              style="
                position: absolute;
                left: 0;
                bottom: 0;
                width: 253px;
                height: 60px;
                line-height: 60px;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                color: rgba(10, 211, 170);
              "
            >
              很不错
            </div>
            <div
              v-if="mode === 4"
              style="
                position: absolute;
                left: 0;
                bottom: 0;
                width: 253px;
                height: 60px;
                line-height: 60px;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                color: rgba(10, 211, 170);
              "
            >
              非常好
            </div>
          </div>
          <div
            style="
              color: #666666;
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr;
              font-size: 14px;
            "
          >
            <div class="progress_box">
              <div style="margin-bottom: 3px">碳水</div>
              <el-progress
                color="#F3AB3C"
                :percentage="
                  content.carbohydrate +
                    content.protein +
                    content.fat +
                    content.dietaryFiber !==
                  0
                    ? Number(
                        (
                          (content.carbohydrate /
                            (content.carbohydrate +
                              content.protein +
                              content.fat +
                              content.dietaryFiber)) *
                          100
                        ).toFixed(2)
                      )
                    : 25
                "
              />
              <div style="margin-top: 3px">
                {{
                  content.carbohydrate +
                    content.protein +
                    content.fat +
                    content.dietaryFiber !==
                  0
                    ? (
                        (content.carbohydrate /
                          (content.carbohydrate +
                            content.protein +
                            content.fat +
                            content.dietaryFiber)) *
                        100
                      ).toFixed(2)
                    : 25
                }}%
              </div>
            </div>
            <div class="progress_box">
              <div style="margin-bottom: 3px">蛋白</div>
              <el-progress
                color="#E06911"
                :percentage="
                  content.carbohydrate +
                    content.protein +
                    content.fat +
                    content.dietaryFiber !==
                  0
                    ? Number(
                        (
                          (content.protein /
                            (content.carbohydrate +
                              content.protein +
                              content.fat +
                              content.dietaryFiber)) *
                          100
                        ).toFixed(2)
                      )
                    : 25
                "
              />
              <div style="margin-top: 3px">
                {{
                  content.carbohydrate +
                    content.protein +
                    content.fat +
                    content.dietaryFiber !==
                  0
                    ? (
                        (content.protein /
                          (content.carbohydrate +
                            content.protein +
                            content.fat +
                            content.dietaryFiber)) *
                        100
                      ).toFixed(2)
                    : 25
                }}%
              </div>
            </div>
            <div class="progress_box">
              <div style="margin-bottom: 3px">脂肪</div>
              <el-progress
                color="#0CD3AB"
                :percentage="
                  content.carbohydrate +
                    content.protein +
                    content.fat +
                    content.dietaryFiber !==
                  0
                    ? Number(
                        (
                          (content.fat /
                            (content.carbohydrate +
                              content.protein +
                              content.fat +
                              content.dietaryFiber)) *
                          100
                        ).toFixed(2)
                      )
                    : 25
                "
              />
              <div style="margin-top: 3px">
                {{
                  content.carbohydrate +
                    content.protein +
                    content.fat +
                    content.dietaryFiber !==
                  0
                    ? (
                        (content.fat /
                          (content.carbohydrate +
                            content.protein +
                            content.fat +
                            content.dietaryFiber)) *
                        100
                      ).toFixed(2)
                    : 25
                }}%
              </div>
            </div>
            <div class="progress_box">
              <div style="margin-bottom: 3px">纤维</div>
              <el-progress
                color="#3C89F3"
                :percentage="
                  content.carbohydrate +
                    content.protein +
                    content.fat +
                    content.dietaryFiber !==
                  0
                    ? Number(
                        (
                          (content.dietaryFiber /
                            (content.carbohydrate +
                              content.protein +
                              content.fat +
                              content.dietaryFiber)) *
                          100
                        ).toFixed(2)
                      )
                    : 25
                "
              />
              <div style="margin-top: 3px">
                {{
                  content.carbohydrate +
                    content.protein +
                    content.fat +
                    content.dietaryFiber !==
                  0
                    ? (
                        (content.dietaryFiber /
                          (content.carbohydrate +
                            content.protein +
                            content.fat +
                            content.dietaryFiber)) *
                        100
                      ).toFixed(2)
                    : 25
                }}%
              </div>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="left">
            <div class="new_title">元素</div>
            <div class="title1">碳水化合物</div>
            <div class="title1">蛋白质</div>
            <div class="title1">脂肪</div>
            <div class="title1">膳食纤维</div>
          </div>
          <div class="center1">
            <div class="new_title">含量</div>
            <div class="title1">
              {{
                content.carbohydrate ? content.carbohydrate.toFixed(1) : 0
              }}g/{{ weightMax.toFixed(1) }}g
            </div>
            <div class="title1">
              {{ content.protein ? content.protein.toFixed(1) : 0 }}g/{{
                weightMax.toFixed(1)
              }}g
            </div>
            <div class="title1">
              {{ content.fat ? content.fat.toFixed(1) : 0 }}g/{{
                weightMax.toFixed(1)
              }}g
            </div>
            <div class="title1">
              {{
                content.dietaryFiber ? content.dietaryFiber.toFixed(1) : 0
              }}g/{{ weightMax.toFixed(1) }}g
            </div>
          </div>
          <div class="right">
            <div class="new_title">推荐量</div>
            <div class="title1">
              {{ nutritionConfig.carbohydrateMin }}-{{
                nutritionConfig.carbohydrateMax
              }}g
            </div>
            <div class="title1">
              {{ nutritionConfig.proteinMin }}-{{ nutritionConfig.proteinMax }}g
            </div>
            <div class="title1">
              {{ nutritionConfig.fatMin }}-{{ nutritionConfig.fatMax }}g
            </div>
            <div class="title1">
              {{ nutritionConfig.dietaryFiberMin }}-{{
                nutritionConfig.dietaryFiberMax
              }}g
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom_title">营养推荐</div>
          <div v-if="qualified.length === 4" class="nutrtionBottom_text">
            请适当补充具有
            <span style="color: #61cfac">
              碳水化合物、蛋白质、膳食纤维、脂肪
            </span>
            的食物,以便均衡您的饮食,维护人体正常生理功能和健康。
          </div>
          <div v-else-if="unqualified.length === 4" class="nutrtionBottom_text">
            减少
            <span style="color: #ffa900">
              碳水化合物、蛋白质、膳食纤维、脂肪
            </span>
            的摄入,以便均衡您的饮食,维护人体正常生理功能和健康。
          </div>
          <div
            v-else-if="qualified.length === 0 && unqualified.length !== 0"
            class="nutrtionBottom_text"
          >
            减少
            <span
              v-for="(fitem, findex) in unqualified"
              :key="fitem"
              style="color: #ffa900"
            >
              {{
                fitem === 'carbohydrate'
                  ? '碳水化合物'
                  : fitem === 'protein'
                  ? '蛋白质'
                  : fitem === 'fat'
                  ? '脂肪'
                  : '膳食纤维'
              }}
              <span v-if="findex !== unqualified.length - 1">、</span>
            </span>
            的摄入,以便均衡您的饮食,维护人体正常生理功能和健康。
          </div>
          <div
            v-else-if="qualified.length !== 0 && unqualified.length === 0"
            class="nutrtionBottom_text"
          >
            请适当补充具有
            <span
              v-for="(citem, cindex) in qualified"
              :key="citem"
              style="color: #61cfac"
            >
              {{
                citem === 'carbohydrate'
                  ? '碳水化合物'
                  : citem === 'protein'
                  ? '蛋白质'
                  : citem === 'fat'
                  ? '脂肪'
                  : '膳食纤维'
              }}
              <span v-if="cindex !== qualified.length - 1">、</span>
            </span>
            的食物,以便均衡您的饮食,维护人体正常生理功能和健康。
          </div>
          <div
            v-else-if="qualified.length === 0 && unqualified.length === 0"
            class="nutrtionBottom_text"
          >
            你的饮食各项元素摄入都很棒，请继续保持。
          </div>
          <div v-else class="nutrtionBottom_text">
            请适当补充具有
            <span
              v-for="(ditem, dindex) in qualified"
              :key="ditem"
              style="color: #61cfac"
            >
              {{
                ditem === 'carbohydrate'
                  ? '碳水化合物'
                  : ditem === 'protein'
                  ? '蛋白质'
                  : ditem === 'fat'
                  ? '脂肪'
                  : '膳食纤维'
              }}
              <span v-if="dindex !== qualified.length - 1">、</span>
            </span>
            的食物,减少
            <span
              v-for="(eitem, eindex) in unqualified"
              :key="eitem"
              style="color: #ffa900"
            >
              {{
                eitem === 'carbohydrate'
                  ? '碳水化合物'
                  : eitem === 'protein'
                  ? '蛋白质'
                  : eitem === 'fat'
                  ? '脂肪'
                  : '膳食纤维'
              }}
              <span v-if="eindex !== unqualified.length - 1">、</span>
            </span>
            的摄入,以便均衡您的饮食,维护人体正常生理功能和健康。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import dish from '@/assets/dish.png';
import headTitle from '@/components/headTitle';
import CommonBorder from '@/components/commonBorder';
export default {
  components: { headTitle, CommonBorder },
  data() {
    return {
      cancel,
      dish,
      apiURL: process.env.VUE_APP_BASE_API,
      order: {},
      user: {},
      nutritionConfig: {},
      content: {
        carbohydrate: 0,
        protein: 0,
        fat: 0,
        dietaryFiber: 0
      },
      weightMax: 0,
      mode: 0,
      qualified: [],
      unqualified: [],
      arr: [],
      arr2: []
    };
  },
  created() {
    this.order = JSON.parse(this.$route.query.order);
    this.nutritionConfig = JSON.parse(this.$route.query.nutritionConfig);
    console.log(this.order);
    this.order.products.map((item) => {
      this.$set(item, 'weight', 100);
      if (!item.fat) {
        item.fat = 0;
      }
      if (!item.carbohydrate) {
        item.carbohydrate = 0;
      }
      if (!item.dietaryFiber) {
        item.dietaryFiber = 0;
      }
      if (!item.protein) {
        item.protein = 0;
      }
    });
    this.computedFn();
  },
  methods: {
    toBack() {
      this.$router.back();
    },
    computedFn() {
      this.weightMax = 0;
      this.arr = [];
      this.arr2 = [];
      this.content = {
        carbohydrate: 0,
        protein: 0,
        fat: 0,
        dietaryFiber: 0
      };
      this.mode = 0;
      this.qualified = [];
      this.unqualified = [];
      this.order.products.map((item) => {
        this.weightMax += item.weight || 0;
        this.content.carbohydrate +=
          (item.carbohydrate * item.weight) / 100 || 0;
        this.content.protein += (item.protein * item.weight) / 100 || 0;
        this.content.fat += (item.fat * item.weight) / 100 || 0;
        this.content.dietaryFiber +=
          (item.dietaryFiber * item.weight) / 100 || 0;
      });
      if (
        this.content.carbohydrate >= this.nutritionConfig.carbohydrateMin &&
        this.content.carbohydrate <= this.nutritionConfig.carbohydrateMax
      ) {
        this.mode += 1;
      }
      if (this.content.carbohydrate > this.nutritionConfig.carbohydrateMax) {
        this.arr2.push('carbohydrate');
      }
      if (this.content.carbohydrate < this.nutritionConfig.carbohydrateMin) {
        this.arr.push('carbohydrate');
      }
      if (
        this.content.protein >= this.nutritionConfig.proteinMin &&
        this.content.protein <= this.nutritionConfig.proteinMax
      ) {
        this.mode += 1;
      }
      if (this.content.protein > this.nutritionConfig.proteinMax) {
        this.arr2.push('protein');
      }
      if (this.content.protein < this.nutritionConfig.proteinMin) {
        this.arr.push('protein');
      }
      if (
        this.content.fat >= this.nutritionConfig.fatMin &&
        this.content.fat <= this.nutritionConfig.fatMax
      ) {
        this.mode += 1;
      }
      if (this.content.fat > this.nutritionConfig.fatMax) {
        this.arr2.push('fat');
      }
      if (this.content.fat < this.nutritionConfig.fatMin) {
        this.arr.push('fat');
      }
      if (
        this.content.dietaryFiber >= this.nutritionConfig.dietaryFiberMin &&
        this.content.dietaryFiber <= this.nutritionConfig.dietaryFiberMax
      ) {
        this.mode += 1;
      }
      if (this.content.dietaryFiber > this.nutritionConfig.dietaryFiberMax) {
        this.arr2.push('dietaryFiber');
      }
      if (this.content.dietaryFiber < this.nutritionConfig.dietaryFiberMin) {
        this.arr.push('dietaryFiber');
      }
      this.qualified = this.arr;
      this.unqualified = this.arr2;
    },
    stepperChangeFn(index) {
      if (this.order.products[index].weight > 500) {
        this.order.products[index].weight = 500;
      } else if (Number(this.order.products[index].weight) === 0) {
        this.order.products[index].weight = 0;
      }
      this.computedFn();
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  padding-top: 44px;
  box-sizing: border-box;
  overflow-x: hidden;
  .nutrition_top {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 20px;
    .title {
      height: 44px;
      line-height: 44px;
      text-indent: 12px;
      font-weight: 900;
      color: #333333;
      border-bottom: 1px solid #eee;
    }
    .content {
      max-height: 102px;
      width: 100%;
      background: #ffffff;
      overflow-y: scroll;
      .content_in {
        height: 102px;
        width: 100%;
        display: flex;
        .left {
          width: 70px;
          height: 70px;
          margin: 15px;
        }
        .right {
          flex: 1;
          color: #333333;
          margin-top: 14px;
          position: relative;
          .bottom {
            margin-top: 25px;
            display: flex;
            align-items: center;
            .unit {
              line-height: 1;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .nutrition_bottom {
    background: #ffffff;
    margin-top: 20px;
    border-radius: 4px;
    width: 100%;
    .title {
      height: 44px;
      line-height: 44px;
      text-indent: 12px;
      font-weight: 900;
      color: #333333;
      border-bottom: 1px solid #eee;
    }
    .top {
      height: 175px;
      border-bottom: 1px dashed #b5b5b5;
      padding-top: 19px;

      .top_img {
        height: 70px;
        width: 253px;
        margin: 0 auto;
        position: relative;
        margin-bottom: 20px;
      }
      .progress_box {
        text-align: center;
      }
    }
    .center {
      height: 196px;
      border-bottom: 1px dashed #b5b5b5;
      display: flex;
      flex-direction: row;
      padding-bottom: 15px;
      font-size: 16px;
      .left {
        width: 30%;
        text-align: center;
      }
      .center1 {
        flex: 1;
      }
      .right {
        width: 25%;
      }
      .new_title {
        color: #333333;
        margin-top: 19px;
        margin-bottom: 9px;
      }
      .title1 {
        font-size: 14px;
        margin-top: 19px;
        margin-bottom: 9px;
        color: #666666;
      }
    }
    .bottom {
      height: 140px;
      padding-bottom: 50px;
      .bottom_title {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        color: #333333;
        height: 44px;
        line-height: 44px;
        text-indent: 12px;
        font-weight: 900;
        color: #333333;
      }
    }
  }
}
.nutrtionBottom_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  padding: 0 15px;
  box-sizing: border-box;
}
::v-deep .van-stepper--round .van-stepper__minus {
  color: #fa5050;
  background-color: #fff;
  cursor: not-allowed;
  border-color: #fa5050;
}
::v-deep .van-stepper--round .van-stepper__plus {
  color: #fff;
  background-color: #fa5050;
  border-color: #fa5050;
}

::v-deep .van-stepper--round .van-stepper__minus--disabled {
  color: #ff7373;
  background-color: #fff;
  border-color: #ff7373;
  cursor: not-allowed;
}
::v-deep .van-stepper--round .van-stepper__plus--disabled {
  color: #fff;
  background-color: #ff7373;
  border-color: #ff7373;
  cursor: not-allowed;
}

::v-deep .el-progress__text {
  display: none;
}

::v-deep .el-progress-bar {
  /* padding-right: 0; */
}

::v-deep .el-progress-bar__outer {
  /* width: 50px; */
  margin-left: -10px;
}
/* ::v-deep .van-stepper__input {
  width: 40px;
  margin: 0 5px;
  text-align: left;
} */
</style>
