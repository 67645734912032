import request from '@/utils/request';

// 获取缴费情况
export function getPayMentList() {
  return request({
    url: '/h5/meal/expense/student/paymentDetail',
    method: 'get'
  });
}

// 获取缴费名单
export function getPayMentDetail(params) {
  return request({
    url: '/h5/meal/expense/student/paymentList',
    method: 'get',
    params
  });
}

// 缴费
export function postPayMent(data) {
  return request({
    url: `/h5/meal/expense/student/payMealExpense?classId=${data}`,
    method: 'post'
  });
}

// 缴费记录
export function getPayMentRecords(params) {
  return request({
    url: '/h5/meal/expense/student/DetailList',
    method: 'get',
    params
  });
}

// 饮食检测-班级名单
export function getClassList(params) {
  return request({
    url: '/h5/dietary/monitor/allusers',
    method: 'get',
    params
  });
}
// 饮食检测-异常名单

export function getClassAbnormalList(params) {
  return request({
    url: '/h5/dietary/monitor/allAbnormalUsers',
    method: 'get',
    params
  });
}

// 获取单个人员的消费记录-全部
export function getPayRecordsDetail(params) {
  return request({
    url: '/h5/dietary/monitor/allConsumeRecords',
    method: 'get',
    params
  });
}

//  获取单个人员的消费记录-异常
export function getPayRecordsDetailAbnormal(params) {
  return request({
    url: '/h5/dietary/monitor/abnormalConsumeRecords',
    method: 'get',
    params
  });
}
