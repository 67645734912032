<template>
  <div class="container">
    <HeadTitle title="缴费名单" return-path="HealthMeal"></HeadTitle>
    <!-- <div class="select-type">
      <div class="select-button" @click="openTypeDialog">
        <div class="name">
          {{ status ? (status === 2 ? '未缴费' : '已缴费') : '全部' }}
        </div>
        <img src="../../../assets/icon-select-type.png" alt="" />
      </div>
    </div> -->
    <div class="record-list" v-if="list.length > 0">
      <div class="record">
        <div class="head">
          <div class="head-title">学生姓名</div>
          <div class="head-title">手机号</div>
          <div class="head-title">金额</div>
          <!-- <div class="head-title">扣费状态</div> -->
        </div>
        <div class="body">
          <div class="list" v-for="item in list" :key="item.id">
            <div class="list-item name">{{ item.userName }}</div>
            <div class="list-item">{{ item.phone }}</div>
            <div class="list-item">¥{{ item.totalAmount / 100 }}</div>
            <!-- <div
              class="list-item"
              :class="item.status === 2 ? 'error' : 'safe'"
            >
              {{ item.status === 2 ? '未缴费' : '已缴费' }}
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <DefaultImg v-else></DefaultImg>
    <!-- 类型选择 -->
    <van-popup v-model="isShowTypeDialog" position="bottom">
      <van-picker
        v-model="status"
        show-toolbar
        :columns="typeList"
        @confirm="onConfirmType"
        @cancel="isShowTypeDialog = false"
        value-key="label"
      />
    </van-popup>

    <!-- 提示弹窗 -->
    <van-dialog
      class="dialog"
      v-model="showPayDialog"
      showCancelButton
      cancelButtonColor="#999999"
      confirmButtonColor="#FFA900"
      confirmButtonText="确定"
      @cancel="showPayDialog = false"
      @confirm="payMeal"
    >
      <template>
        <div class="head">
          <div class="title">温馨提示</div>
          <div class="content">确认收取伙食费吗?</div>
        </div>
      </template>
    </van-dialog>

    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import DefaultImg from '@/components/defaultImg/index.vue';
import HeadTitle from '@/components/headTitle/index.vue';
import { getPayMentDetail, postPayMent } from '@/api/health-meal.js';
export default {
  components: { HeadTitle, DefaultImg },
  data() {
    return {
      isShowTypeDialog: false,
      showPayDialog: false,
      typeList: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '未缴费',
          value: 2
        },
        {
          label: '已缴费',
          value: 3
        }
      ],
      classId: this.$route.query.classId,
      show: false,
      status: '',
      list: []
    };
  },
  async created() {
    await this.getList();
  },
  mounted() {},
  methods: {
    async getList() {
      try {
        this.show = true;
        const res = await getPayMentDetail({
          status: this.status,
          classId: this.classId
        });
        this.list = res.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.show = false;
      }
    },

    openTypeDialog() {
      this.isShowTypeDialog = true;
    },
    async onConfirmType(item) {
      this.status = item.value;
      await this.getList();
      this.isShowTypeDialog = false;
    },
    async payMeal() {
      try {
        this.show = true;
        const res = await postPayMent(this.classId);
        if (res.code === 0) {
          this.$router.push({
            name: 'PayDetail',
            query: {
              classId: this.classId,
              detail: res.data
            }
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.show = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 44px;
  box-sizing: border-box;
  .select-type {
    width: 375px;
    height: 50px;
    opacity: 1;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;

    .select-button {
      border-radius: 4px;
      background: #f7f7f7;
      display: flex;
      align-items: center;
      padding: 8px 13px;
      color: #666666;
      font-size: 18px;
      font-weight: 400;
      height: 34px;
      box-sizing: border-box;
      img {
        width: 19px;
        height: 19px;
        margin-left: 4px;
      }
      .name {
        color: #666666;
        font-size: 18px;
        font-weight: 400;
        font-family: '苹方';
      }
    }
    .pay-button {
      width: 62px;
      height: 28px;
      border-radius: 4px;
      opacity: 1;
      background: #ffa900;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .record-list {
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
    .record {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 5px 15px 0 #00000014;

      // 使用grid布局使head和list垂直对齐
      .head,
      .body .list {
        display: grid;
        grid-template-columns: repeat(3, 1fr); // 根据列数进行分配
        /* gap: 10px; */
        align-items: center; // 垂直居中对齐
        padding: 14px 12px;
        color: #333333;
        font-size: 16px;
      }

      .head {
        font-weight: 500;
        position: relative;
        .head-title {
          /* 居中 */
          display: flex;
          justify-content: center;
        }
      }

      .head::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        transform: scaleY(0.5);
        background-color: #e5e5e5;
      }

      .body {
        .list {
          font-weight: 400;
          .list-item {
            /* 居中 */
            display: flex;
            justify-content: center;
            /* 允许换行 */
            /* flex-wrap: wrap; */
            /* word-break: break-word; // 处理换行 */
          }
        }
      }
      // 针对日期列不换行
      /* .head-title:nth-child(1),
      .list-item:nth-child(1) {
        white-space: nowrap; // 防止换行
      } */
      .head-title:nth-child(3),
      .list-item:nth-child(3) {
        white-space: nowrap; // 防止换行
      }
    }
  }
}

.name {
  max-width: 64px;

  word-wrap: break-word !important; /* 允许在单词内断行 */
}

::v-deep .van-picker__toolbar {
  height: 50px;
  background-color: #f1f1f1;
  .van-picker__cancel {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    font-family: 'PingFangSC-Regular, sans-serif';
  }
  .van-picker__confirm {
    font-size: 16px;
    font-weight: 500;
    color: #ffa900;
    font-family: 'PingFangSC-Regular, sans-serif';
  }
}
.dialog {
  width: 343px;
  height: 161px;
  border-radius: 10px;
  .head {
    box-sizing: border-box;
    height: 110px;
    padding-top: 28px;
    text-align: center;

    .title {
      color: #333333;
      font-size: 18px;
      font-family: 'PingFangSC-Regular';
      font-weight: 500;
      line-height: 18px;
    }
    .content {
      font-size: 16px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #666666;
      margin: 24px 0;
      line-height: 16px;
    }
  }
  ::v-deep .van-button__text {
    font-size: 18px;
    font-family: 'PingFangSC-Regular';
    font-weight: 500;
    line-height: 18px;
  }
}
.error {
  color: #ed3944;
}
.safe {
  color: #00b893;
}
</style>
